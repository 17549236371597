/* ------------------------------ GENERAL ------------------------------ */

$(function () {
    // Hide the gift card if the customer is identified on the Global-e site
    window.addEventListener('loadGlobalE_complete', function() {
        if (isGlobalEActive) {
            $('#main_menu #carte-cadeau, #main_menu #gift-card').hide();
        }
    });

    if ($(".bandeau_header").length) {
        $("body").addClass("hasBando");
    }

    $("#main_menu_btn").on("click", function () {
        $(".banner_wrapper").css("z-index", "30");
    });

    $(".filters_cmd button").on("click", function () {
        $(".banner_wrapper").css("z-index", "28");
    });

    $(".modal_shade").on("click touch", function () {
        $("#filters_menu").removeClass("open");
        $("#account_menu").removeClass("active");
        $("#user_btn").removeClass("active");
    });

    onWindowScroll();

    getWishlistProducts();

    if ($(".cms-page-module").find(".tag").length) {
        tagPosition();
    }

    $(".tag")
        .on("mouseenter touchStart", function () {
            $(this).parent().find(".cms-page-column img").addClass("filter_on");
        })
        .on("mouseleave", function () {
            $(this)
                .parent()
                .find(".cms-page-column img")
                .removeClass("filter_on");
        });

    /*
     * Pre-header slider
     */
    if ($("#sliderBando").length) {
        if ($("#sliderBando .swiper-wrapper .swiper-slide").length > 1) {
            setTimeout(function () {
                new Swiper("#sliderBando", {
                    loop: true,
                    autoplay: 3000,
                    speed: 2200,
                    effect: "slide",
                    autoplayDisableOnInteraction: false,
                    direction: "vertical",
                });
            }, 800);
        } else {
            new Swiper("#sliderBando", {
                slidesPerView: 1,
            });
        }
    }

    $(".footer_accordion_ctn .title").each(function () {
        $(this).click(function () {
            if ($(this).parent().hasClass("active")) {
                $(this).parent().removeClass("active");
                $(this)
                    .parent()
                    .find(".footer_navs")
                    .removeClass("active")
                    .slideUp(300);
            } else {
                $(".footer_accordion_ctn .ctn").removeClass("active");
                $(".footer_accordion_ctn .footer_navs")
                    .removeClass("active")
                    .slideUp(300);
                $(this).parent().addClass("active");
                $(this)
                    .parent()
                    .find(".footer_navs")
                    .addClass("active")
                    .slideDown(300);
            }
        });
    });

    /**
     * Switch between cart and wishlist view in basket
     */
    initToggleCartTypeBtn();

    /**
     * Add 'withUpselling' class on tunnel summary when upselling is active
     * needed for positioning cart push
     */
    if (
        ($(".cart.step_1").length && $(".upselling_alma").length) ||
        ($(".cart.step_2").length && $(".upselling_alma").length)
    ) {
        $("#tunnel_right_col").addClass("withUpselling");
    }

    /**
     * Init Cart association slider if detected
     */
    if ($(".cartAssociations").length) {
        updateCartElements();
    }

    /**
     * Init function if '.formPanier_container' available on the current page
     */
    if ($(".formPanier_container").length) {
        setTimeout(function () {
            formPanierClass();
        }, 500);
    }

    /**
     * Switch cart disconnection position
     */
    if ($(".deconnection_tunnel").length) {
        $(".deconnection_tunnel").insertAfter($("#cart_total"));
    }

    /**
     * Hide cart summary for new customer step 2
     */
    if ($(".step_2_0").length) {
        $("#cart_total").hide();
    }

    /**
     * Switch toggle details line position
     */
    if ($(".total_produit.toggle").length) {
        switchToggleCartpos();
    }

    if ($("#blocs_address").length || $(".wrapper_payment").length) {
        switchAddrRecapCartpos();
    }

    /**
     * Toggle product details in cart right column
     */
    $(".total_produit").on("click", function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });

    /**
     * Prevent default event on 'modlink' href
     */
    var addNewAddressBtn = $(".add_new_address");
    var modifyAddressBtn = $(".modlinks_wrapper .modify");

    addNewAddressBtn.click(function (event) {
        event.preventDefault();
    });

    modifyAddressBtn.click(function (event) {
        event.preventDefault();
    });

    /* ------------------------------ FAQ ------------------------------ */
    // Global theme boxes selection
    $("#theme_global .theme").on("click", function () {
        $("body.faq").addClass("actif");
        $("#theme_global .theme.active").removeClass("active");
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
        } else {
            $(this).addClass("active");
        }
    });

    /* ------------------------------ MODULES ------------------------------ */
    if ($("#slideCateg .swiper-slide").length) {
        slideCateg = new Swiper("#slideCateg", {
            slidesPerView: "auto",
        });
    }

    /** COVER MODULE */
    if ($(".sliderMainHome").length) {
        new Swiper(".sliderMainHome", {
            roundLengths: true,
            slidesPerView: 1,
            observeParents: true,
            observer: true,
            pagination: ".cover-module-pagination",
        });
    }

    /** MODULE IMAGES CTA */
    if ($("#trois-image-cta").length) {
        new Swiper("#trois-image-cta", {
            observeParents: true,
            observer: true,
            slidesPerView: 1,
        });
    }

    /** MODULE GONDOLE INTEREST ACHAT EXPRESS ADD BASKET */
    if ($("#gondole_interest").length) {
        new Swiper("#gondole_interest", {
            observeParents: true,
            observer: true,
            slidesPerView: 2.5,
            spaceBetween: 12,
        });
    }

    /** MODULE 4 IMAGES  */
    if ($(".four-images").length) {
        new Swiper(".four-images .swiper-container", {
            slidesPerView: 1,
        });
    }

    /* ------------------------------ ACCOUNT ------------------------------ */
    // Swiper account menu
    if (
        $("body").hasClass("customer") ||
        $("body").hasClass("newsletter") ||
        $("body").hasClass("order") ||
        $("body").hasClass("wishlist") ||
        ($("body").hasClass("paymentOption") &&
            $("#menu_account_slider_mob").length > 0)
    ) {
        var menuInitialSlide = 0;

        $(".menu_account_slider_mob").css({ display: "block", opacity: "0" });

        $(".menu_account_slider_mob .swiper-slide").each(function (i, obj) {
            if ($(obj).hasClass("activeMenu")) {
                menuInitialSlide = i;
            }
        });
        var menuAccSwiperMob = new Swiper("#menu_account_slider_mob", {
            slidesPerView: "auto",
            initialSlide: menuInitialSlide,
            nextButton: ".swiper-button-next",
            prevButton: ".swiper-button-prev",
            slidesOffsetBefore: 30,
            slidesOffsetAfter: 30,
            onInit: function () {
                $(".menu_account_slider_mob").fadeTo("slow", 1);
            },
        });
    }

    var accountLoginWrapper = $("body.customer.body_login .wrapper_mon_compte");
    var bandeauLoginImg = $("body.customer.body_login .wrapper_mon_compte img");

    if (bandeauLoginImg) {
        var bandeauLoginImgUrl = bandeauLoginImg.attr("src");
        accountLoginWrapper.css(
            "--image-url",
            "url(" + bandeauLoginImgUrl + ")"
        );
    }

    $("input[name='autoAddress']").on("click touch", function () {
        $(".noaddr").show();
    });

    updateFpElements();

    /**
     * Init .item_container slider on page loaded
     */
    initItemSlider(false);

    /*
     * Other categ slider
     */
    if ($(".bloc_sub_categ").length) {
        var currentCateg;

        $(".bloc_sub_categ .swiper-slide").each(function (i, obj) {
            if ($(obj).hasClass("current")) {
                currentCateg = i;
            }
        });

        const bloc_sub_categ = new Swiper($(".bloc_sub_categ"), {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: "auto",
            centeredSlides: true,
            initialSlide: currentCateg,
            spaceBetween: 10,
        });

        setTimeout(function () {
            $(".bloc_sub_categ").addClass("appear");
        }, 500);
    }

    /**
     * Category SEO txt read more
     */
    if ($(".txt.trimed").length) {
        var trimedTxt = $(".txt.trimed");
        var fullTxt = $(".txt.full");
        var read_more = $(".read_more");
        var read_less = $(".read_less");

        read_more.on("click", function () {
            $(this).hide();
            read_less.show();
            trimedTxt.removeClass("displayed");
            fullTxt.addClass("displayed");
        });

        read_less.on("click", function () {
            $(this).hide();
            read_more.show();
            trimedTxt.addClass("displayed");
            fullTxt.removeClass("displayed");
        });
    }

    /**
     * Store reviews slider
     */
    if ($("#bloc_reviews_slider").length) {
        new Swiper("#bloc_reviews_slider", {
            roundLengths: true,
            slidesPerView: 1.2,
            observeParents: true,
            observer: true,
            spaceBetween: 15,
            pagination: {
                el: ".storeReviewsPagination",
                clickable: true,
            },
        });
    }

    // INJECT FLOWBOX

    if ($('body.product_page').length) {
        let intervalID = setInterval(addTitleToFlowbox, 100);
        let counter = 0;
        const maxRepetitions = 17;
        function addTitleToFlowbox() {
            if ($('#flowbox-container').length && !$('#flowbox-container').is(':empty')) {
                /* #flowbox-container found, stop repetitions */
                $(".flowBoxBloc .wrapper").addClass("actif");
                clearInterval(intervalID);
            } else if (counter >= maxRepetitions) {
                /* Stop repetitions after 17 times */
                $(".flowBoxBloc").remove()
                clearInterval(intervalID);
            }
            ++counter;
        }
    }

    setTimeout(function () {
        if (isGlobalEActive) {
            $(".disable_globale").each(function () {
                if (!$(this).hasClass("disabled")) {
                    $(this).addClass("disabled");
                }
            });
        }
    }, 6000);

    if ($(".videos-module .swiper").length) {
        new Swiper(".videos-module .swiper", {
            pagination: ".videos-module-pagination",
        });
    }

    /* home-mobile-menu sticky to bottom on page load and normal on scroll */
    if ($("body").hasClass("homepage")) {
        $(window).scroll(function () {
            var scrollPosition = $(window).scrollTop();

            if (scrollPosition >= 10) {
                $(".home-mobile-menu").addClass("menu-normal");
            } else {
                $(".home-mobile-menu").removeClass("menu-normal");
            }
        });
    }

    $(".fp_characts").on("click touch", function () {
        $(".attribute").removeClass("active");
    });
    $(".characts_8").on("click touch", function () {
        $(".attribute_8").toggleClass("active");
    });
    $(".characts_9").on("click touch", function () {
        $(".attribute_9").toggleClass("active");
    });
    $(".characts_10").on("click touch", function () {
        $(".attribute_10").toggleClass("active");
    });
    $(".characts_11").on("click touch", function () {
        $(".attribute_11").toggleClass("active");
    });
    $(".characts_12").on("click touch", function () {
        $(".attribute_12").toggleClass("active");
    });
    $(".characts_13").on("click touch", function () {
        $(".attribute_13").toggleClass("active");
    });

    if ($(".txt.trimed").length) {
        var trimedTxt = $(".txt.trimed");
        var read_more = $(".read_more");
        var read_less = $(".read_less");
        var heightToToggle = trimedTxt.height();
        trimedTxt.css("height", "75px");
        read_more.on("click", function () {
            trimedTxt.addClass("cache");
            trimedTxt.animate({ height: heightToToggle }, 500);
            read_more.addClass("cache");
            read_less.removeClass("cache");
        });
        read_less.on("click", function () {
            trimedTxt.removeClass("cache");
            trimedTxt.animate({ height: "75px" }, 500);
            read_more.removeClass("cache");
            read_less.addClass("cache");
        });
    }

    $(".form_itm_wrapper .form_itm.size").on("click", function () {
        if ($(".error_text.error").hasClass("error")) {
            $(".error_text.error").removeClass("error");
        } else {
            $(".error_text.noSizeSelected").hide();
        }
    });

    /* swiper on satellite univers pages */
    if ($("#timeline .swiper-slide").length > 0) {
        timelineSwiper = new Swiper("#timeline", {
            slidesPerView: 1.443,
        });
    }

    if ($("#banner-swiper .swiper-slide").length > 0) {
        selection = new Swiper("#banner-swiper", {
            slidesPerView: 1,
            spaceBetween: 30,
            paginationClickable: true,
            pagination: ".swiper-pagination",
        });
    }

    $(".swiper-btn").on("click", function () {
        if ($("#our_selection .swiper-slide").length > 0) {
            setTimeout(function () {
                ourSelection = new Swiper("#our_selection", {
                    slidesPerView: 1.3,
                    spaceBetween: 15,
                    centeredSlides: true,
                    paginationClickable: true,
                    pagination: ".swiper-pagination",
                });
            }, 200);
        }
    });

    /* add scroll os */
    if ($(".text-wrapper.company-information-wrapper").length) {
        setTimeout(function () {
            $(".company-info-block").overlayScrollbars({});
        }, 250);
    }

    /* switch function on satellite univers pages */
    $("select#category").on("change", function () {
        var selectElement = $(this).find("option:selected");
        var btnVal = selectElement.attr("data-filter");

        $(".switch-slider").each(function () {
            let getFilterName = $(this).attr("data-filter");
            if (btnVal == getFilterName) {
                /* Removed active from all that are not identical */
                $(".switch-slider").removeClass("active");
                /* Add Class active to the switch-slider that have the same data filter as the switch-btn */
                $(this).addClass("active");
            }
        });
    });

    /* switch on satellite univers pages */
    $(".btn-switch").each(function () {
        $(this).on("click touch tap", function () {
            let targetId = $(this).data("filter");

            // let bandeauOffset = $(".bandeau_header").hasClass("none") ? 0 : 40;
            // $("html, body").animate(
            //     {
            //         scrollTop:
            //             $("#" + targetId).offset().top -
            //             bandeauOffset -
            //             $(".banner_nav").outerHeight(),
            //     },
            //     400
            // );

            /* Check if switch-btn instances has active class */
            if (!$(this).hasClass("active")) {
                /* Remove all active class on all switch button */
                $(".btn-switch").removeClass("active");
                /* Add class active only on clicked button */
                $(this).addClass("active");

                /* Get the value of data-filter for each instances of switch-btn */
                var getBlocName = $(this).attr("data-filter");
                var $correspondingSlider = $(".switch-slider[data-filter='" + getBlocName + "']");

                if ($correspondingSlider.length > 0) {
                    $(".switch-slider").removeClass("active");

                    $correspondingSlider.addClass("active");

                    var newUrl = window.location.href.split('#')[0];

                    if (getBlocName) {
                        newUrl += "#" + getBlocName;
                    }

                    window.history.pushState({}, '', newUrl);
                }
            }
        });
    });

    /* header banner to transparent on satellite univers pages */
    if ($(".satellite-page").length > 0) {
        $("body").addClass("universe-pages");
    }
    if (document.body.classList.contains("universe-pages")) {
        showElementOfUrlId();
    }

    $(".interactive-part").each(function () {
        $(this).on("click touch tap", function () {
            let getTiggerPartName = $(this).attr("data-name");
            let topElement = $("." + getTiggerPartName).offset().top - 220;
            $("html").css("scroll-behavior", "smooth");
            $(window).scrollTop(topElement);
            $('.expandable').removeClass('open');
            $('.accordion-trigger').removeClass('open');

            $(".block").each(function () {
                let getPartName = $(this).attr("data-name");
                if (getPartName == getTiggerPartName) {
                    $(this).find('.expandable').addClass('open');
                    $(this).find('.accordion-trigger').addClass('open');
                }
            })
        });
    });

    /* accordion function on satellite univers pages */
    $(".accordion-trigger").each(function () {
        $(this).on("click touch tap", function () {
            if ($(this).hasClass("open")) {
                $(this).removeClass("open");
                $(this).next().removeClass("open");
                $(this).parent().removeClass('overlay-active');
            } else {
                $(".accordion-trigger").removeClass("open");
                $(".accordion-trigger").parent().removeClass('overlay-active');
                $(".expandable").removeClass("open");
                $(this).addClass("open");
                $(this).next().addClass("open");
                $(this).parent().addClass('overlay-active');
            }
        });
    });

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            }, 500);
        }
    });

    /* home-mobile-menu sticky to bottom on page load and normal on scroll */
    if ($('body').hasClass('homepage')) {

        $(window).scroll(function () {
            var scrollPosition = $(window).scrollTop();

            if (scrollPosition >= 10) {
                $('.home-mobile-menu').addClass('menu-normal');
            } else {
                $('.home-mobile-menu').removeClass('menu-normal');
            }
        });
    }

    if ($('#qr_code_account').length) {
        $('.qr_code_account_title_wrapper').on('click', function() {
            $('#qr_code_account').toggleClass('open');
        });
    }

    // For contact page (Used in Jonak US)
    if ($(".contact_faq #form_contact1").length) {
        // Similarly to desktop, display the contact CTAs by JS first, then allow CSS to hide them when form is validated
        $(".contact_faq .contactForm_content_right .contact").css("display", "block");
    }

    $('.form_itm.check.size').has('.no_stock').click(function() {
        $(".bloc_add_alert_confirmation").css("display", "none");
        $(".bloc_add_alert_form").css("display", "block");
        $(".alert_stock").css("display", "block");
    } );

    clickableBoxCookies();

    $(document).on("click touch", "#item_entretien .prodSize", function () {
        var bloc_achat_express = $(this).parents('.achat_express');
        var form = $(this).parents('form');
        var productId = form.attr('id').split("_")[2];

        // Check if achat_express is closed
        const aeChecker = setInterval(() => {
            if ($(bloc_achat_express).css('display') === 'none') {
                // Move form back to its initial position
                $('#item_entretien .swiper-slide[data-produit-id*="' + productId + '"] .wrap_rolloverproduit > .wrap_rolloverproduit').append(form);

                form.removeClass('active');

                clearInterval(aeChecker);
            }
        }, 100);
    });
});

/*
 * Switch lang
 */
function changeLocale(locale, uri, redirect = true) {
    setTimeout(function () {
        $.ajax({
            url: path_relative_root + create_link('ajax_get_uri_by_lang'),
            type: "post",
            data: { lang: locale, url: uri, redirect: redirect },
            success: function (data) {
                if (data != "") {
                    var force = "";
                    if (uri == "/") {
                        force = "?force";
                    }

                    var href_lang = data + force;

                    if (typeof href_change_lang != "undefined") {
                        href_lang = href_change_lang + href_lang;
                    }

                    if (redirect) {
                        location.href = href_lang;
                    } else {
                        urlLang = data;
                    }
                }
            },
        });
    }, 400);
}

/**
 * Add 'scrolled' class on body when scrolling down 50px
 * Permits to switch header colors for the header on the homepage
 */
function onWindowScroll() {
    var pageBody = document.body;
    var oldScrollY = window.scrollY;

    window.onscroll = function (e) {
        if (oldScrollY < window.scrollY && oldScrollY > 50) {
            pageBody.classList.add("scrolled");

            if (!document.body.classList.contains("product_page")) {
                show_search();
            }
        } else {
            pageBody.classList.remove("scrolled");

            if (!document.body.classList.contains("product_page")) {
                hide_search();
            }
        }

        oldScrollY = window.scrollY;
    };
}

/**
 * Search management
 */
function show_search() {
    var top_search = document.getElementById("top_searchV2");

    document.body.classList.add("SearchWhite");
    top_search.classList.add("displayed");
}

function close_search() {
    var top_search = document.getElementById("top_searchV2");
    var search_homeV2 = document.getElementById("search_homeV2");
    var searchIco = document.getElementById("searchIco");

    searchIco.classList.remove("reset");
    searchIco.classList.add("search");

    if (search_homeV2.value == "") {
        document.body.classList.remove("SearchWhite");
        top_search.classList.remove("displayed");
    } else {
        search_homeV2.value = "";
    }
}

/*
 * Used only during scroll events
 */
function hide_search() {
    var top_search = document.getElementById("top_searchV2");
    var search_homeV2 = document.getElementById("search_homeV2");

    if (search_homeV2.value == "" && document.activeElement != search_homeV2) {
        document.body.classList.remove("SearchWhite");
        top_search.classList.remove("displayed");
        var banner = document.querySelector(".banner_nav");
        banner.style.display = "flex";
    }
}

/*
 * Function triggered when search input is focused (called in _app FO vue-app-mobile.js)
 */
function customSearchFunction() {
    var search_homeV2 = document.getElementById("search_homeV2");
    var searchIco = document.getElementById("searchIco");

    if (search_homeV2.value != "") {
        searchIco.classList.remove("search");
        searchIco.classList.add("reset");
    } else {
        searchIco.classList.remove("reset");
        searchIco.classList.add("search");
    }
}

/*
 * Main menu accordion sscat
 * */
$(document).on("click touch", ".site_bottom .navlist", function () {
    if ($(this).hasClass("displayed")) {
        $(this).removeClass("displayed");
    } else {
        $(this).addClass("displayed");
    }
});

alert = (function () {
    "use strict";

    var shade, albox, alert_tmpl;

    function init() {
        shade = document.getElementById("shade");
        albox = document.getElementById("alert_box");

        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {
        modBoxClose.call(document.getElementById("alert_box"));
    }

    function alert(str) {
        $(".alert_box_content", albox).html(str);

        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);

        modBoxOpen.call(document.getElementById("alert_box"));
    }

    window.addEventListener("load", init);

    return alert;
})();

function openMultiShad(id) {
    // Close menu first if it is open
    if ((id == 'country_switcher_lightbox') && $("#left_menu").hasClass('open')) {
        toggleMenu('left', 'left');
    }

    $("#" + id).addClass("actif");
    $("body").addClass("noscroll");

    if (!$("#shade").hasClass("actif")) {
        $("#shade").addClass("actif");
    }

    if (id != "lightbox_preco") {
        $("#shade").on("click", function () {
            closeMultiShad(id);
        });
    }
}

function closeMultiShad(id) {
    $("#" + id).removeClass("actif");
    $("#shade").removeClass("actif");
    $("body").removeClass("noscroll");

    if (id === "availabilityInStore") {
        if ($('#displayAvailableStock').attr('checked')) {
            $('#displayAvailableStock').click();
        }
    }

    if (id === "country_switcher_lightbox") {
        $.ajax({
            url: path_relative_root + "ajax_set_session_country_code.php",
            type: "post",
            data: {value: $('.country_initials').html().toUpperCase()},
            success: function (res) {},
        });
    }
}

function switchToggleCartpos() {
    if ($(".checkout").length) {
        $(".total_produit.toggle").insertBefore($(".bank_left_side"));
        $(".total_produit_dropdown").insertBefore($(".bank_left_side"));
    } else {
        $(".total_produit.toggle").insertBefore($(".left_side.elem_tunnel"));
        $(".total_produit_dropdown").insertBefore($(".left_side.elem_tunnel"));
    }
}

function switchAddrRecapCartpos() {
    if ($(".checkout").length) {
        $(".wrapper_payment").insertBefore($(".bank_left_side"));
    } else {
        $("#blocs_address").insertBefore(".left_side.elem_tunnel");
    }
}

// Initialize and filter and show corresponding questions according to user's keyword
function faqinit(array_questions) {
    $(document).on("change keyup focus", "#faq_search", function () {
        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {
            $(".search_results li").show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $(".search_results").show();
                    $("li#brand_" + val["id"]).addClass("hasMatch");
                }
            });

            $(".brand_item:not(.hasMatch)").hide();

            if (array_match.length == 0) {
                $(".search_results").hide();
            }
        } else {
            $(".search_results").hide();
            $(".search_results li").show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $(".search_results").mousedown(function () {
        click_in_process = true;
    });

    $(".search_results").mouseup(function () {
        click_in_process = false;
        $("#faq_search").focus();
    });

    $("#faq_search").blur(function () {
        if (!click_in_process) {
            $(".search_results").hide();
            $(".search_results li").show().removeClass("hasMatch");
        }
    });

    $("#theme_global .theme").on("click", function () {
        var id = $(this).data("id");
        var theme = $("#theme_page");
        $("#theme_global").removeClass("actif");
        $(".title_detail_sub").css("display", "none");
        theme.addClass("actif");
        showTabFaq(id, 1);
        $("html, body").animate(
            { scrollTop: $(".faq_theme_" + id + "").offset().top - 100 },
            1000
        );
    });

    $("#theme_page .theme").on("click", function () {
        var id = $(this).data("id");
        showTabFaq(id, 1);
    });
}

function researchFaq(node_id, level, question_id) {
    $(".search_results").hide();
    $(".search_results li").show().removeClass("hasMatch");

    if ($("#theme_global").hasClass("actif")) {
        $("#theme_global").removeClass("actif");
        $("#theme_page").addClass("actif");
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate(
        { scrollTop: $(".faq_question_" + question_id + "").offset().top - 130 },
        1000
    );
}

// ORDERS
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {
    var divId =
        "popup_numero_retour_" + (multiTransp ? idOrderTransp : idOrder);

    var numDIVObjet = document.getElementById(divId);

    numDIVObjet.style.display = "block";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = "1";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    // Open shad
    openMultiShad(divId);

    $("html, body").animate({ scrollTop: 0 }, speed * 2, function () { });
}

function showPdfEtiquetteRetour(
    idOrder,
    multiTransp,
    numRetour,
    show_pdf,
    idOrderTransp
) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $("#return_products_form_" + idCmdOrder).serialize();
        data +=
            "&idOrder=" +
            idCmdOrder +
            "&multiTransp=" +
            multiTransp +
            "&return_number=" +
            numRetour;
    } else {
        data =
            "idOrder=" +
            idCmdOrder +
            "&multiTransp=" +
            multiTransp +
            "&return_number=" +
            numRetour;
    }

    $.ajax({
        url: path_relative_root + "ajax_create_etiquette.php",
        type: "post",
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == "false") {
                alert(
                    Translator.translate(
                        "ajax_sprintf.php?arg1=js_error_return_label"
                    )
                );
            } else {
                closeMultiShad("popup_numero_retour");
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        },
    });
}

function createEtiquetteRetour(idOrder, multiTransp) {

    var has_errors = false;
    $(".return_quantity").each(function() {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0  && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form_' + idOrder).serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp;

        $.ajax({
            url : path_relative_root + 'ajax_create_etiquette.php',
            type : 'post',
            data : data,
            success : function (res) {
                location.reload();
            }
        });
    }
}

function loadProduct(type_filter, id, type_page, itm_rr_id) {
    var tmp_qte = $("#qteProd_" + id).val();
    var is_wishlist = false;
    var is_achat_express_v2 =
        this.form !== undefined &&
        this.form.elements["achatExpressV2"] !== undefined &&
        !is_wishlist;

    if (typeof id == "undefined") {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof type_page == "undefined") {
        type_page = "product";
    }

    var $form = "";
    var data = "";

    if (!is_achat_express_v2) {
        $form = $("#item_add_form");
        var $input = $("#prod_" + type_filter + id_prod, $form);
        var type = type_filter;
        var $hidden = $("#prod_" + type_filter + id_prod, $form);

        if (typeof id == "undefined") {
            data += $form.serialize();
        }

        var couleur_id = 0;

        couleur_id = $("#prod_couleur input[type=radio]:checked").val();
        $produit_id_new = $("#prod_" + type_filter + id_prod)
            .find(":selected")
            .attr("data-produitid");
        if ($produit_id_new == undefined) {
            $produit_id_new = $("#prod_" + type_filter + id_prod)
                .find(":checked")
                .attr("data-produitid");
        }

        data += "&couleurProd=" + couleur_id;
        data += "&tailleProd=" + $("#prod_taille").val();
        data += "&type=" + type_filter;
        data += "&type_page=" + type_page;
        if (document.getElementById("wishlist")) {
            data += "&type_page=" + type_page;
        }

        if (typeof id != "undefined") {
            data += "&couleurProd=" + $("#couleurProd" + id_prod).val();
            data += "&tailleProd=" + $("#tailleProd" + id_prod).val();
            data += "&produit_principal=" + $("#produit_principal" + id_prod).val();
            var produit_principal = $("#produit_principal" + id_prod).val();
        }

        data += "&produit_id_new=" + $produit_id_new;
        var elemClass = $(".reloadProduct")
            ? ".reloadProduct"
            : ".swiper-slide"; //Sélection de la class  sur le vertical_product pour le reload du produits (Master = 'reloadProduct' , Override = 'swiper-slide') ex: Manoush
        var div_produit_achat_express_v2 = $(this).parents(elemClass);
    } else {
        var elemClass = $(this).parents(".reloadProduct").length
            ? ".reloadProduct"
            : ".swiper-slide"; //Sélection de la class  sur le vertical_product pour le reload du produits (Master = 'reloadProduct' , Override = 'swiper-slide') ex: Manoush
        var div_produit_achat_express_v2 = $(this).parents(elemClass)
        var swiper_achat_express_v2 = $(this).parents(".swiper-container");

        $form = $(this.form);
        var id_prod = "";
        $form = this.form;

        var old_id = this.form.elements["produit_id"].value;
        var isCategSuper = this.form.elements["isCategSuper"].value;
        var new_id = id;
        itm_rr_id = itm_rr_id !== "" ? "_" + itm_rr_id.split("_")[1] : "";
        var cpt_prod = itm_rr_id !== "" ? itm_rr_id.replace("_", "") : "";

        var couleur_id = 0;
        couleur_id = $(this).val();

        data += "produit_id=" + old_id;
        data += "&produit_principal=" + old_id;
        data += "&couleurProd=" + couleur_id;
        data += "&tailleProd=";
        data += "&qteProd=1";
        data += "&hdn_product_id=" + old_id;
        data += "&produit_id_new=" + new_id;
        data += "&type=" + type_filter;
        data += "&type_page=" + type_page;
        data += "&is_achat_express=0";
        data += "&is_achat_express_v2=1";
        data += "&cpt_prod=" + (cpt_prod - 1);
        data += "&isCategSuper=" + isCategSuper;
    }

    if (isGlobalEActive) {
        data += "&warehouse_only=1";
    }

    $.ajax({
        type: "post",
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        beforeSend: function () {
            $("#bloc_btn_loader").show();
            $("#btn_error").fadeOut(300);
        },
        success: function (response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            if (response.success == "ok") {
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        event: "view_item",
                    });
                }

                var datas = response.datas;

                if (type_page == "product") {
                    var $html_main = $(datas.html_main);

                    if (is_achat_express_v2) {
                        // Photo et information
                        div_produit_achat_express_v2.replaceWith($html_main);
                        if (
                            $("#oneProduct") != undefined &&
                            $("#oneProduct").hasClass("actif")
                        ) {
                            $(".itm_achat_express_" + new_id).addClass("full");
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof four_images != "undefined") {
                                enableSwiperFourImage();
                            } else {
                                if(!$(("body.product_page .gondole_derniers_articles_vus").length)) {
                                    var id_swiper = swiper_achat_express_v2.attr("id");
                                    refreshSliderAchatExpress( id_swiper );
                                }
                            }
                        }

                        $("#shade")
                            .removeClass("isExpress")
                            .removeClass( "visible" );
                        if ($(".full.btnAddBasketWrapper.noSizeAvailable").length > 0) {
                            $( ".full.btnAddBasketWrapper.noSizeAvailable" ).append( `<div class="lightbox_buttons">
                                    <div class="form_submit">
                                        <button class="button cancel" onclick="PopUpNotAvailable();">
                                            <span>${Translator.translate('close')}</span>
                                        </button>
                                    </div>
                                </div>` );
                        }
                        setTimeout(function () {
                            initItemSlider(false);
                        }, 200);
                    } else {
                        var $fiche = $("#contents_wrapper");
                        var replace_main = new Array(
                            "#wrapper_product_name",
                            "#zoom_container",
                            ".prod_page_bot",
                            ".associations_section",
                            ".accordion_container",
                            ".product_infos .price_container",
                            ".cms-page-module",
                            "w-netreviews-stars",
                            "w-netreviews-comments",
                            ".netreviews_reviews",
                            ".accordion_item #productRef",
                            "#item_carac",
                            "#item_description",
                            "#traceBox",
                            "#list_fp_characts",
                        );

                        var product_refext_prod = $(
                            "input[name=itm_refext_prod]",
                            $html_main
                        ).val();

                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id == "w-netreviews-stars") {
                                // New Avis verifies
                                $("#w-netreviews-stars", $fiche).html(
                                    $("w-netreviews-stars", $html_main).html()
                                );
                                // Reload vuejs avis verifies
                                if (
                                    typeof wShop !== "undefined" &&
                                    wShop.$refs.wNetreviewsStars
                                ) {
                                    // Avis verifies stars
                                    if (
                                        wShop.$refs.wNetreviewsStars !=
                                        "undefined"
                                    ) {
                                        wShop.$refs.wNetreviewsStars.reload(
                                            product_refext_prod
                                        );
                                    }
                                }
                            } else if (id == "w-netreviews-comments") {
                                // New Avis verifies
                                $("#w-netreviews-comments", $fiche).replaceWith(
                                    $("w-netreviews-comments", $html_main)
                                        .wrap("<p/>")
                                        .parent()
                                        .html()
                                );
                                // Reload vuejs avis verifies
                                if (
                                    typeof wShop !== "undefined" &&
                                    wShop.$refs.wNetreviewsComments
                                ) {
                                    // Avis verifies reviews
                                    if (
                                        wShop.$refs.wNetreviewsComments !=
                                        "undefined"
                                    ) {
                                        wShop.$refs.wNetreviewsComments.reload(
                                            product_refext_prod
                                        );
                                    }
                                }
                            } else {
                                // In case info is not present in response, remove it (ex: cms-page-module on one ref but not on an other)
                                if (
                                    $(id, $fiche).length &&
                                    $(id, $html_main).length
                                ) {
                                    $(id, $fiche).html(
                                        $(id, $html_main).html()
                                    );
                                } else {
                                    if (
                                        $(id, $fiche).length &&
                                        !$(id, $html_main).length
                                    ) {
                                        $(id, $fiche).replaceWith("");
                                    }
                                }

                                /******* OLD AVIS VERIFIES ************/
                                if (id == ".netreviews_reviews") {
                                    if (
                                        $("#avis_verifie_produit").length ||
                                        $(".netreviews_reviews").length ||
                                        $(".netreviews_stars").length
                                    ) {
                                        if (
                                            typeof netreviews_product_url !=
                                            "undefined" &&
                                            netreviews_product_url !== null
                                        ) {
                                            // netreviews_product_url defined in views_mobile_new/main.php
                                            (function () {
                                                var script =
                                                    document.createElement(
                                                        "script"
                                                    );
                                                script.setAttribute(
                                                    "id",
                                                    "ajax_verified"
                                                );
                                                script.setAttribute(
                                                    "type",
                                                    "text/javascript"
                                                );
                                                // script.setAttribute("src", "//cl.avis-verifies.com/fr/cache/1/7/d/17d1a320-136f-2ca4-4553-bcc2817af7ba/widget4/17d1a320-136f-2ca4-4553-bcc2817af7baproduit_script.js");
                                                script.setAttribute(
                                                    "src",
                                                    netreviews_product_url
                                                );

                                                // If ajax already being done, verify if script exists before adding it to HEAD
                                                if (
                                                    document.getElementById(
                                                        "ajax_verified"
                                                    ) !== null
                                                ) {
                                                    document
                                                        .querySelector(
                                                            "#ajax_verified"
                                                        )
                                                        .remove();
                                                }

                                                document
                                                    .getElementsByTagName(
                                                        "head"
                                                    )[0]
                                                    .appendChild(script);
                                            })();
                                        }
                                    }

                                    if (
                                        typeof nrSearchForStars !==
                                        "undefined" &&
                                        $(".NETREVIEWS_PRODUCT_STARS").length
                                    ) {
                                        nrSearchForStars();
                                    }

                                    // Display avis verifie comments
                                    // Call nrSearchForReviews from tag.min.js avis verifie NETREVIEWS_PRODUCT_URL
                                    if (
                                        typeof nrSearchForReviews !==
                                        "undefined" &&
                                        $(".NETREVIEWS_PRODUCT_REVIEWS").length
                                    ) {
                                        nrSearchForReviews();
                                        setTimeout(function () {
                                            if (
                                                $(
                                                    "#netreviews_reviews_tab"
                                                ).height() === undefined
                                            ) {
                                                $("#avis_verifies").hide();
                                            } else {
                                                $("#avis_verifies").show();
                                            }
                                        }, 1500);
                                    }
                                }
                            }
                        }

                        /******* ASSOCIATED PRODUCTS ************************************/
                        /***************************************************************/

                        var slides_per_view =
                            typeof associatedProduct_spv != "undefined"
                                ? associatedProduct_spv
                                : 3;
                        var asso_btn_prev =
                            typeof assoBtnPrev != "undefined"
                                ? assoBtnPrev
                                : ".swiper-button-prev";
                        var asso_btn_next =
                            typeof assoBtnNext != "undefined"
                                ? assoBtnNext
                                : ".swiper-button-next";
                        var asso_space_between =
                            typeof assoSpaceBetween != "undefined"
                                ? assoSpaceBetween
                                : 20;
                        var asso_swiper_params =
                            typeof assoSwiperParams != "undefined"
                                ? assoSwiperParams
                                : {
                                    slidesPerView: slides_per_view,
                                    spaceBetween: asso_space_between,
                                    nextButton: asso_btn_prev,
                                    prevButton: asso_btn_next,
                                };

                        // Associations_section = tabs (=> onglets)
                        if ($(".associations_section", $html_main).length > 0) {
                            // Check if associated products are displayed as tabs
                            if (
                                $(
                                    ".product_associations_wrapper",
                                    $html_main
                                ).attr("data-tab") !== undefined
                            ) {
                                // First check if there's an active class on each titles and wrappers
                                if (!$(".association_title.active").length) {
                                    $(".association_title")
                                        .first()
                                        .addClass("active");
                                }

                                if (
                                    !$(".product_associations_wrapper.active")
                                        .length
                                ) {
                                    $(".product_associations_wrapper")
                                        .first()
                                        .addClass("active");

                                    // Init also swiper on page load
                                    if (
                                        $(
                                            ".product_associations_wrapper.active .swiper-container .swiper-slide"
                                        ).length >
                                        Math.floor(
                                            asso_swiper_params.slidesPerView
                                        )
                                    ) {
                                        new Swiper(
                                            ".product_associations_wrapper.active .swiper-container",
                                            asso_swiper_params
                                        );
                                    }
                                }

                                // Once first title and first swiper is active, handle click on each tabs dynamically
                                $(".association_title").on(
                                    "click",
                                    function () {
                                        var title_tab =
                                            $(this).attr("data-tab");
                                        var active_tab = $(
                                            ".product_associations_wrapper.active"
                                        );

                                        // If the clicked title is not active yet, set it to active and remove active class from the last title
                                        if (!$(this).hasClass("active")) {
                                            $(
                                                ".association_title.active"
                                            ).removeClass("active");
                                            $(this).addClass("active");
                                        }

                                        // If new active title, display matching associations tab
                                        if (
                                            title_tab !=
                                            active_tab.attr("data-tab")
                                        ) {
                                            active_tab.removeClass("active");
                                            $(
                                                '.product_associations_wrapper[data-tab="' +
                                                title_tab +
                                                '"]'
                                            ).addClass("active");

                                            // Swiper activation
                                            if (
                                                $(
                                                    ".swiper-container",
                                                    '.product_associations_wrapper[data-tab="' +
                                                    title_tab +
                                                    '"]'
                                                )
                                            ) {
                                                // If more than slides_per_view, we activate the swiper
                                                if (
                                                    $(
                                                        '.product_associations_wrapper[data-tab="' +
                                                        title_tab +
                                                        '"] .swiper-slide'
                                                    ).length >
                                                    Math.floor(
                                                        asso_swiper_params.slidesPerView
                                                    )
                                                ) {
                                                    setTimeout(
                                                        new Swiper(
                                                            '.product_associations_wrapper[data-tab="' +
                                                            title_tab +
                                                            '"] .swiper-container',
                                                            asso_swiper_params
                                                        ),
                                                        500
                                                    );
                                                }
                                            }
                                        }
                                    }
                                );
                            }
                        } else {
                            // Init product_associations_wrapper swiper(s)
                            if ($(".product_associations_wrapper").length > 1) {
                                $(".product_associations_wrapper").each(
                                    function () {
                                        if (
                                            $(this).find(".swiper-slide")
                                                .length >=
                                            Math.floor(
                                                asso_swiper_params.slidesPerView
                                            )
                                        ) {
                                            new Swiper(
                                                $(this).find(
                                                    ".swiper-container"
                                                ),
                                                asso_swiper_params
                                            );
                                        }
                                    }
                                );
                            } else if (
                                $(".product_associations_wrapper").length > 0
                            ) {
                                if (
                                    $(".product_associations_wrapper").find(
                                        ".swiper-slide"
                                    ).length >=
                                    Math.floor(asso_swiper_params.slidesPerView)
                                ) {
                                    new Swiper(
                                        ".product_associations_wrapper .swiper-container",
                                        asso_swiper_params
                                    );
                                }
                            }
                        }

                        // If there's not an associations_section working with a tabulation mechanism -> check carousel_wrapper
                        if (!$(".associations_section", $html_main).length) {
                            if ($("#carousel_wrapper", $html_main).length > 0) {
                                // For sites using the _app view, some sites don't want one particular swipers on reload so remove it from dom
                                if (typeof carouselException != "undefined") {
                                    var new_carousel = $(
                                        "#carousel_wrapper",
                                        $html_main
                                    );

                                    // Removing carousel exception from html_main response
                                    if (
                                        $(
                                            ".ranges_carousel_wrapper." +
                                            carouselException,
                                            new_carousel
                                        ).length
                                    ) {
                                        $(
                                            ".ranges_carousel_wrapper." +
                                            carouselException,
                                            new_carousel
                                        ).remove();
                                    }

                                    $("#carousel_wrapper").html(
                                        new_carousel.html()
                                    );
                                } else {
                                    $("#carousel_wrapper").html(
                                        $(
                                            "#carousel_wrapper",
                                            $html_main
                                        ).html()
                                    );
                                }

                                // Check if site has declared several associated swipers for its FP
                                if (
                                    typeof oAssociatedSwipers != "undefined" &&
                                    typeof oAssociatedSwipers == "object"
                                ) {
                                    // Loop and init swipers
                                    $.each(
                                        oAssociatedSwipers,
                                        function (container, params) {
                                            // Check if there are enough slides to init swiper
                                            if ($(container).length > 0) {
                                                if (
                                                    $(container).find(
                                                        ".swiper-slide"
                                                    ).length >=
                                                    params.slidesPerView
                                                ) {
                                                    // Check if swiper-container is a sibling or a child
                                                    setTimeout(
                                                        new Swiper(
                                                            $(container),
                                                            params
                                                        ),
                                                        500
                                                    );
                                                }
                                            }
                                        }
                                    );
                                } else {
                                    // If swipers are identical, init them the classic way
                                    if (
                                        $("#carousel_wrapper .swiper-container")
                                            .length > 1
                                    ) {
                                        $(
                                            "#carousel_wrapper .swiper-container"
                                        ).each(function () {
                                            if (
                                                $(this).find(".swiper-slide")
                                                    .length >=
                                                asso_swiper_params.slidesPerView
                                            ) {
                                                setTimeout(
                                                    new Swiper(
                                                        $(this),
                                                        asso_swiper_params
                                                    ),
                                                    500
                                                );
                                            }
                                        });
                                    } else if (
                                        $("#carousel_wrapper .swiper-container")
                                            .length > 0
                                    ) {
                                        if (
                                            $(
                                                "#carousel_wrapper .swiper-container"
                                            ).find(".swiper-slide").length >=
                                            asso_swiper_params.slidesPerView
                                        ) {
                                            setTimeout(
                                                new Swiper(
                                                    "#carousel_wrapper .swiper-container",
                                                    asso_swiper_params
                                                ),
                                                500
                                            );
                                        }
                                    }
                                }
                            } else {
                                $("#carousel_wrapper").html("");
                            }
                        }
                        if ($("#publication_page", $html_main).length > 0) {
                            $("#publication_page").html(
                                $("#publication_page", $html_main).html()
                            );
                        } else {
                            $("#publication_page").html("");
                        }

                        window.prod_slider = document.getElementById(
                            "product_pictures_slider"
                        );
                        window.asso_slider = document.getElementById(
                            "asso_product_carousel"
                        );

                        if (
                            asso_slider &&
                            $(".swiper-slide", asso_slider).length > 0 &&
                            typeof assoSwiperParams == "undefined"
                        ) {
                            var assoSwiperSlider = new Swiper(
                                ".swiper-container.asso_product_carousel",
                                {
                                    slidesPerView: 3,
                                    centeredSlides: true,
                                    paginationClickable: true,
                                    spaceBetween: 20,
                                    pagination: ".swiper-pagination",
                                    preloadImages: true,
                                    lazyLoading: true,
                                    loop: true,
                                    nextButton: ".swiper-button-next",
                                    prevButton: ".swiper-button-prev",
                                }
                            );
                        }

                        /* Special case for a single view slider */
                        if (
                            $(".assocSlider").length > 0 &&
                            $(".assocSlider .swiper-slide").length >= 1 &&
                            typeof assoSwiperParams == "undefined"
                        ) {
                            var assoSlider = new Swiper(".assoSlider", {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                nextButton: ".swiper-button-next",
                                prevButton: ".swiper-button-prev",
                            });
                        }

                        //set slider product
                        if (
                            prod_slider &&
                            $(".swiper-slide", prod_slider).length > 1
                        ) {
                            var slidesPerView =
                                $("#zoom_container").data("slidesperview");
                            if (slidesPerView == undefined) {
                                slidesPerView = 1;
                            }
                            var spaceBetween =
                                $("#zoom_container").data("spacebetween");
                            if (spaceBetween == undefined) {
                                spaceBetween = 0;
                            }
                            var prodSwiperSlider = new Swiper(
                                "#zoom_container .swiper-container",
                                {
                                    slidesPerView: slidesPerView,
                                    mode: "horizontal",
                                    centeredSlides: true,
                                    pagination: ".swiper-pagination",
                                    paginationClickable: true,
                                    preloadImages: true,
                                    lazyLoading: true,
                                    spaceBetween: spaceBetween,
                                    loop: true,
                                    nextButton: ".swiper-button-next",
                                    prevButton: ".swiper-button-prev",
                                }
                            );
                        }

                        // After swiper load, reload dailymotions videos
                        try {
                            initDailymotionVideos();
                        } catch (e) {
                            console.error(e);
                        }

                        var string_params = "";
                        if ($("#produit_lot_nb").val() != undefined) {
                            string_params +=
                                "&produit_lot_nb=" + $("#produit_lot_nb").val();
                        }

                        if ($("#tailleProd").val() > 0) {
                            // RECHARGEMENT QTE
                            var qte = ajax_file(
                                path_relative_root +
                                "ajax_liste_qte.php?idprod=" +
                                datas.product.id +
                                "&idcouleur=" +
                                datas.product.color.id +
                                "&idtaille=" +
                                $("#tailleProd").val() +
                                string_params
                            );
                            $("#liste_qte").html(qte);
                        }

                        // If we have one size we directly check the stock of the product (cf VB)
                        if (
                            $('input[name="itm_one_size"]').length &&
                            $('input[name="itm_one_size"]').val() === "1"
                        ) {
                            onAttrSelect(parseFloat(datas.product.id));
                        }

                        var contentMovable = $("#content_movable");

                        $(".jqzoom").on("doubletap", function () {
                            zoomImg.call(this);
                            $("#zoomBox").addClass("active");

                            contentMovable.addClass("visible");
                            var zoomboxWidth = $("body").width(),
                                scrollTo = zoomboxWidth / 2;
                            setTimeout(function () {
                                $("#zoomBox").scrollLeft(scrollTo);
                            }, "slow");
                        });

                        contentMovable.on("doubletap", function () {
                            $(this).removeClass("visible");
                            $("#zoomBox").removeClass("active");
                        });

                        // var accordion_container = $('.product_page .accordion_container');
                        // var data_accordion =$('.accordion_container')
                        //check stock product
                        var hasStock = false;
                        var stocks = response.stock;
                        // Check for each stocks if val = true
                        for (var key in stocks) {
                            if (stocks[key] == true) {
                                hasStock = true;
                                $("#size_" + key).removeClass("disabled");
                            } else {
                                $("#size_" + key).addClass("disabled");
                            }
                        }

                        //si on a du stock, on affiche les éléments correspondant
                        if (hasStock) {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").show();
                            }

                            if ($("#button_add") !== undefined) {
                                $("#button_add").show();
                            }

                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").show();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").hide();
                            }
                            //sinon, on les cache
                        } else {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").hide();
                            }

                            if ($("#button_add") !== undefined) {
                                $("#button_add").hide();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").hide();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").show();
                            }
                        }
                    }
                    if (
                        $("#oneProduct").length &&
                        $("#oneProduct").hasClass("actif")
                    ) {
                        $("div.item_container").addClass("full");
                    }
                } else if (type_page == "look") {
                    var $html_main = $(datas.main_content);

                    $("#ref_look_" + produit_principal).html(
                        $("#ref_look_" + produit_principal, $html_main).html()
                    );
                    $("#look_add_lightbox_" + produit_principal).html(
                        $(
                            "#look_add_lightbox_" + produit_principal,
                            $html_main
                        ).html()
                    );

                    calculTotal("slide_look_detail");

                    var $id_current = id_prod.substring(
                        id_prod.lastIndexOf("_") + 1
                    );
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {
                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme(
                        "liste_qte_" + datas.product.id,
                        datas.product.id,
                        path_relative_root
                    );
                } else if (type_page == "assoc") {
                    var $html_main = $(datas.html_main);
                    $("#ref_assoc_" + produit_principal).html(
                        $("#ref_assoc_" + produit_principal, $html_main).html()
                    );

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme(
                        "liste_qte_" + datas.product.id,
                        datas.product.id,
                        path_relative_root
                    );
                }

                if (
                    $("#btn_add_alert", response.datas.html_main) &&
                    $("#btn_add_alert", response.datas.html_main).attr(
                        "onclick"
                    ) &&
                    $("#btn_add_alert", response.datas.html_main)
                        .attr("onclick")
                        .indexOf("lightboxAlertStock") !== false
                ) {
                    $(".eclat_product_nouveaute_fiche_produit").show();
                }
            } else {
                $("#btn_error").fadeIn();
            }

            if (document.getElementById("alma-widget") !== null) {
                initAlmaWidget();
            }

            // Créate CustomEvent "loadProduct_success" to succes LoadProduct
            var evt = new CustomEvent("loadProduct_success");
            window.dispatchEvent(evt);
        },
        complete: function () {
            $("#bloc_btn_loader").fadeOut(300);

            if (window.lazyload) {
                lazyload.init();
            }

            var form = document.getElementById("item_add_form");

            if (is_achat_express_v2) {
                form = $form;
            } else {
                colors = form.itm_colour;
                color = colors ? colors.value : "none";
                color_checked = color != "none" ? $("#color_" + color) : "";
                updateColor.call(color_checked);
            }

            setTimeout(function () {
                $(".zoom_indicator").fadeOut(800);
            }, 2500);

            if (type_page == "product") {
                $("#ctaAvailabilityInStoreTitle").html(Translator.translate('availabilityInStore'));
            }

            // Create CustomEvent "loadProduct_complete" to complete LoadProduct
            var evt = new CustomEvent("loadProduct_complete");
            window.dispatchEvent(evt);

            // WPOS Exclusive - Get if product is available in store use in WPOS
            var idStore = getCookie('wshopStoreId');
            var idProduct = getCookie('p_id');
            if (idStore != undefined && idStore > 0) {
                var color = $("#prod_couleur").val();
                var size = $("#prod_taille").val();
                getProductInfoByStore(idProduct, color, size, idStore)
            }
        },
    });
}

// Updates visual and thumbnail for selected color
function updateColor() {
    var item_visu = document.getElementById("img_large");
    // var colr_visu = document.getElementById('preview_color');
    var item_url = this.attr("data-itm-img");
    var colr_url = path_relative_root + "img/couleur/" + this.value + ".jpg";
    var colr_hexa = this.attr("data-hexa");
    var is_model = this.attr("data-ismodel");
    var has_photo = this.attr("data-color-img");

    if (has_photo == "0") {
        has_photo = false;
    }

    if (this.value != "") {
        // cible et affiche images pr chaque couleur selectionnée
        if ($("#img_large.product_picture").length > 0) {
            $("#img_large.product_picture").each(function (index) {
                $(this).attr("src", item_url);
            });
        } else {
            item_visu.src = item_url;
        }
    }
}

/**
 * Triggers on attribute alteration.
 *
 * @param {number} idProduit - The product ID.
 */
function onAttrSelect(idProduit) {
    var form = document.getElementById("item_add_form"),
        colors = form.itm_colour,
        sizes = form.itm_size,
        quantities = form.itm_quantity,
        color,
        size;

    if (this.value) {
        this.classList.remove("error");
    }

    color = colors ? colors.value : "none";
    color_checked = color != "none" ? $("#color_" + color) : "";
    if (color_checked) {
        idProduit = color_checked.attr("data-produit_id") != ""
            ? color_checked.attr("data-produit_id")
            : idProduit;
    }

    if (color !== "none") {
        updateColor.call(color_checked);
        if (this !== window && this.classList.contains("color")) {
            setAvailableSizes.call(this);
        }
    }

    if (color == "") {
        if ($('input[name="firstColorStock"]') !== undefined) {
            var color = $('input[name="firstColorStock"]').val();
            if (color) {
                $('#prod_couleur option[value="' + color + '"]').prop(
                    "selected",
                    true
                );
            }
        }
    }

    size = sizes ? sizes.value : "none";
    size_checked = size != "none" ? $("#size_" + size) : "";

    if ($("#prod_taille").length > 0) {
        $("#prod_taille").val(size);
    }

    if (sizes !== undefined) {
        var nbsize = sizes.length === undefined ? 0 : sizes.length;
        var sizeValue = size && size_checked !== undefined
            ? size_checked.attr("data-nom")
            : 0;

        if (size && nbsize > 1 && sizeValue !== undefined) {
            //mise à jour du DataLayer
            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    event: "changeSize",
                    produit: {
                        size_value: sizeValue,
                    },
                });
                if (window.dataLayer[0].product) {
                    window.dataLayer[0].product.size = $(this).attr("data-nom");
                }
            }
        }
    }

    if (quantities) {
        $('#button_add + .loader').addClass('loading');
        $.ajax({
            url: path_relative_root + "ajax_liste_qte.php",
            type: "get",
            async: true, // Should be async but would then need a loader so... edit gt : async: true
            dataType: "json",
            data: {
                idprod: idProduit,
                idcouleur: color,
                idtaille: size,
                json: "1",
                isGlobalEActive: isGlobalEActive,
            },
            success: function (res) {
                let inventoryData = res.inventory;
                let productIsSfs = res.is_sfs !== "undefined" ? res.is_sfs : false;

                var lastPiecesEclatBlock = $("#content_product #lastPiecesEclat");
                var noStockEclat = $("#zoom_container .noStockEclat");
                /*
                    It can be useful when you need to display an eclat to let the customer know that the product isn't available at the moment:
                    - If the eclat exists, add a class depending on the availabality of the product for the selected size
                    - If my class is present on the element, I can display my eclat using style on the front office
                    (be sure to add your eclat in the view file depending on the project you're working on)
                */
                var noStock = (!inventoryData.enableAddToCart && isGlobalEActive); 

                // If no_stock eclat exists
                if (noStockEclat.length > 0 || noStock) {
                    if (size != "") {
                        // If size is not empty == a real size has been selected in the FP select box
                        if (parseInt(inventoryData.stockQuantity) == 0) {
                            // If theres no stock on this size and the item hasn't the no_stock class yet
                            if (!$("#zoom_container").hasClass("size_no_stock")) {
                                $("#zoom_container").addClass("size_no_stock");
                            }
                        } else {
                            // If there's stock but the no_stock class was already added from a previous size selection
                            if ($("#zoom_container").hasClass("size_no_stock")) {
                                $("#zoom_container").removeClass("size_no_stock");
                            }
                        }
                    } else {
                        // If the user hasn't selected a real size but the no_stock class was already added from a previous size selection
                        if ($("#zoom_container").hasClass("size_no_stock")) {
                            $("#zoom_container").removeClass("size_no_stock");
                        }
                    }
                }

                // If last pieces eclat exists
                if (lastPiecesEclatBlock.length > 0 && alerte_last_stock != undefined && !noStock) {
                    if (parseInt(inventoryData.stockQuantity) <= parseInt(alerte_last_stock) &&parseInt(inventoryData.stockQuantity) !== 0) {
                        lastPiecesEclatBlock.addClass("is-visible");
                    } else {
                        lastPiecesEclatBlock.removeClass("is-visible");
                    }
                }

                var content = res.content;
                quantities.parentElement.innerHTML = content.trim();
                if (size != "") {
                    result = $($("#qteProd option:selected"), content);
                    if ($("#bloc_add_alert").length > 0) {
                        if ($("#bloc_another_basket").length > 0 && inventoryData.stockQuantity > 0 && inventoryData.stockQuantity == 0) {
                            $("#bloc_add_alert").hide();
                            $("#add_basket_wrapper").hide();
                            $(".stock_check").hide();
                        } else {
                            if (result.hasClass("no_stock") || noStock) {
                                $("#bloc_add_alert").show();
                                $("#add_basket_wrapper").hide();
                                $(".stock_check").hide();
                            } else {
                                if (
                                    typeof use_preorder_with_alert ==
                                    "undefined" ||
                                    (typeof use_preorder_with_alert !=
                                        "undefined" &&
                                        res.produit_precommande != "oui")
                                ) {
                                    $("#bloc_add_alert").hide();
                                }
                                $("#add_basket_wrapper").show();
                                $(".stock_check").show();
                            }
                        }
                    } else {
                        if (result.hasClass("no_stock") || noStock) {
                            onAttrDisableAddCart();
                        } else {
                            onAttrEnableAddCart();
                        }
                    }

                    $('#button_add + .loader').removeClass('loading');
                }

                checkProductInWishlist();

                if (!inventoryData.enableAddToCart) {
                    if (isGlobalEActive && !productIsSfs) {
                        $("#add_basket_wrapper").hide();
                        $(".bloc_add_alert").show();
                    }

                    if (typeof res.is_personalize !== "undefined" && res.is_personalize == true) {
                        $(".btn_personalize").hide();
                    } else {
                        $(".btn_personalize").show();
                    }
                }
            },
        });

        if ($('.button_ask').length == 0) {
            var data = {
                produitId: idProduit,
                colorId: color,
                sizeId: size,
                json: '1'
            };

            $.ajax({
                type: 'post',
                data: data,
                url: path_relative_root + 'ajax_get_stock_by_store.php',
                success: function (res) {
                    showSimpleBloc = false;
                    showMultipleBloc = false;
                    if (res['stock'] <= availabilityInStoreStockLevelLow || res['store'][0].magasin_click_and_go == 0) {
                        traduction = Translator.translate('store_locator_product_unavailable')
                        classe = '';
                        showSimpleBloc = true;
                    } else if (res['stock'] > availabilityInStoreStockLevelLow && res['stock'] <= availabilityInStoreStockLevelMedium) {
                        traduction = Translator.translate('store_locator_product_limited')
                        classe = 'limited';
                        showMultipleBloc = true;
                    } else {
                        traduction = Translator.translate('store_locator_product_available')
                        classe = 'available';
                        showMultipleBloc = true;
                    }
                    if (showSimpleBloc) {
                        $("#bloc_availability .disponibility_other_store").show();
                        $("#bloc_availability .disponibility_change").hide();
                        $("#bloc_availability .disponibility_retire").hide();
                    } else if (showMultipleBloc) {
                        $("#bloc_availability .disponibility_other_store").hide();
                        $("#bloc_availability .disponibility_change").show();
                        $("#bloc_availability .disponibility_retire").show();
                    }
                    $(".clickandgo_product_info .availability").removeClass('limited');
                    $(".clickandgo_product_info .availability").removeClass('available');
                    $(".clickandgo_product_info .availability").addClass(classe);
                    $(".clickandgo_product_info .availability .label_availability").html(traduction);
                }
            });
        }
    }
    var idStore = getCookie('wshopStoreId');
    if (idStore != undefined && idStore > 0) {
        getProductInfoByStore(idProduit, color, size, idStore)
    }
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : "";
    var is_wishlist = $("#wishlist").length > 0;

    if ($("#tunnel_right_col").length > 0) {
        is_wishlist = false;
    }

    var is_achat_express_v2 =
        $("#is_achat_express_v2").val() == 1 && !is_wishlist;

    if (this.checked) {
        control = form.elements.namedItem(form.getValue("idCouleur"));
        control.value = this.value.trim();
        prod_id = form.getValue("itm_id_" + control.value + itm_rr_id).trim();

        if (is_regroup) {
            loadProduct.call(form, prod_id, "couleur", "", itm_rr_id);
        } else {
            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist();
            }

            var img = $(this).data("pic");

            changeVisu("img_large", img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var is_wishlist = $("#wishlist").length > 0;

    if ($("#tunnel_right_col").length > 0) {
        is_wishlist = false;
    }

    var is_achat_express_v2 =
        $("#is_achat_express_v2").val() == 1 && !is_wishlist;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : "";

    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                event: "changeSize",
                produit: {
                    size_value: $(this).attr("data-nom"),
                },
            });

            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = $(this).attr("data-nom");
            }
        }

        if ($("#sizeerror").is(":visible")) {
            $("#sizeerror").slideUp();
        }

        var id = form.getValue("produit_id");
        var elem = is_achat_express_v2 ? "_" + id + itm_rr_id : "";

        control = form.elements.namedItem("tailleProd");
        control.value = this.value.trim();

        // En fonction de la couleur on regarde si le produit est disponible
        getAvailableQuantity(
            $("#produit_principal" + elem).val(),
            $("#qteProd" + elem).val(),
            "product",
            event,
            itm_rr_id,
            is_wishlist,
            this
        );
        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            checkProductInWishlist();
        }
    }
}

// WISHLIST
// add product to basket from wishlist (connected)
function moveProductFromWishlistToCart(elemBtn) {

    $(elemBtn).addClass('processing');
    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    let datas = {
        data: $(elemBtn).attr("data-id"),
    };

    if (prodId !== undefined) {
        datas.productId = prodId;
    }

    if (colorId !== undefined) {
        datas.colorId = colorId;
    }

    if (sizeId !== undefined) {
        datas.sizeId = sizeId;
    }

    $.post(path_relative_root + "ajax_add_panier_wishlist.php", datas, function (resp) {
        if (resp === "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

/************************************/
/*          CART FUNCTIONS          */

/************************************/
/**
 * Switch cart btn class depending on its viewport position
 */
function formPanierClass() {
    let formPanier_container = $(".formPanier_container");
    let elementBottom =
        formPanier_container.offset().top + formPanier_container.outerHeight();

    if ($(document).scrollTop() + $(window).height() <= elementBottom) {
        formPanier_container.addClass("fixed");
    } else {
        formPanier_container.removeClass("fixed");
    }

    $(window).on("scroll", function () {
        if ($(document).scrollTop() + $(window).height() <= elementBottom) {
            formPanier_container.addClass("fixed");
        } else {
            formPanier_container.removeClass("fixed");
        }
    });
}

/**
 * After ajax called in basket (also called in _app)
 */
function updateCartElements() {
    var cartAssocSwiper = $(".cartAssocSwiper");

    // Placement of associations just after cart summary
    $(".cartAssociations").insertAfter($("#cart_total"));

    /**
     * Cart associations slider init
     */
    setTimeout(function () {
        if (cartAssocSwiper.length) {
            $(".cartAssociations").addClass("appear");
        }
    }, 300);

    moveProductOutOfStock();

    initToggleCartTypeBtn()
}

/*
 * Called to reload sliders and other elements on product page
 */
function updateFpElements() {
    if ($(".text_image_3").length) {
        new Swiper(".text_image_3 .swiper-container", {
            slidesPerView: 1.2,
            spaceBetween: 10,
        });
    }
}

/*
 * _app override
 * Common views between Desktop and Mobile
 */
function changeQte(type) {
    var types = ["plus", "less"],
        item,
        loader,
        qtty,
        ajax_url;

    if (types.includes(type)) {
        item = this.getValue("item_id", parseFloat);
        loader = document.getElementById("qte_loader_" + item);
        qtty = this.getValue("selected_qte", parseFloat);

        this.qtty_select_wrap.style.display = "none";
        loader.style.display = "initial";

        switch (type) {
            case "plus":
                qtty += 1;
                break;
            case "less":
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: "post",
            data: {
                action: "del",
                panierId: item,
                cad: "non",
                pointcad: 0,
                qteProd: qtty,
                change_qte: true,
            },
            cache: false,
            success: function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error("JSON ERROR: could not parse data");
                    return false;
                }

                res = $("<div />").html(res.datas);

                document.getElementById("main_cart_wrapper").innerHTML = $(
                    "#main_cart_wrapper",
                    res
                ).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                $("#cart_btn").data("cart", response);

                /**
                 * Move out of stock products to the top of the cart
                 */
                updateCartElements();

                if (typeof initApplePayExpressCheckout === "function") {
                    initApplePayExpressCheckout();
                }
            },
        });
    }
}

/*
 * Main menu accordion sscat
 * */
$(document).on("click touch", ".displayAccordion", function () {
    var btnClicked = $(this);
    var nextUl = $(this).next(".sub_cat_menu");

    if (nextUl.hasClass("displayed")) {
        btnClicked.removeClass("opened");
        nextUl.slideUp(400, function () {
            nextUl.removeClass("displayed");
        });
    } else {
        btnClicked.addClass("opened");
        nextUl.slideDown(600, function () {
            nextUl.addClass("displayed");
        });
    }
});

// Add class on module tags to improve lightbox positioning on hover
function tagPosition() {
    // stores the tag container in each module in an array
    var moduleArray = [
        {
            module: ".cms-page-module.image-module.images2",
            wrapper: ".blocImage",
        },
        {
            module: ".cms-page-module.slider-cover-module.cover-look",
            wrapper: ".cms-page-columns-container",
        },
        {
            module: ".cms-page-module.text-image-4",
            wrapper: ".cms-page-columns-container",
        },
        {
            module: ".cms-page-module.image-module.images3",
            wrapper: ".blocImage",
        },
        {
            module: ".cms-page-module.image-module.images4",
            wrapper: ".blocImage",
        },
        {
            module: ".cms-page-module.image-module.images5",
            wrapper: ".blocImage",
        },
    ];

    // iteration in each module
    moduleArray.forEach(function (obj) {
        if ($(obj.module).length) {
            var imgWrapper = $(obj.module).find(obj.wrapper);

            var centerPos = imgWrapper.width() / 2;
            var middlePos = imgWrapper.height() / 2;

            // set the tag new position in each module
            imgWrapper.find(".tag").each(function () {
                var tagCenterPos = 7 + parseInt($(this).css("left"));
                var tagMiddlePos = 7 + parseInt($(this).css("top"));

                if (tagCenterPos >= centerPos) {
                    $(this).removeClass("leftPos").addClass("rightPos");
                } else {
                    $(this).removeClass("rightPos").addClass("leftPos");
                }

                if (tagMiddlePos >= middlePos) {
                    $(this).removeClass("topPos").addClass("bottomPos");
                } else {
                    $(this).removeClass("bottomPos").addClass("topPos");
                }
            });
        }
    });
}

$(window).scroll(function () {
    if ($(".bandeauMenuAffix").length && !$("#GlobaleFreeShippingBannerContainer:has(> #FreeShippingBanner)").length) {
        if ($(document).scrollTop() > 50) {
            $(".hasBando").removeClass("hasBando");
            $("body").addClass("bandoCollapsed");
        } else {
            $("body").addClass("hasBando");
            $(".bandoCollapsed").removeClass("bandoCollapsed");
        }
    }
});

function searchCategoryFilter() {
    window.setTimeout(function () {
        new Swiper("#slideCateg", {
            slidesPerView: "auto",
        });
    }, 500);
}

function showElementOfUrlId() {
    const url = window.location.href;
    const urlSegments = url.split("#")[1];

    if (urlSegments) {
        $("html, body").animate(
            {
                scrollTop: $(`#${urlSegments}`).offset().top - 36,
            },
            400
        );

        addActiveClass(`[data-filter="${urlSegments}"]`, urlSegments);
    } else if (url.includes("shoe_care")) {
        addActiveClass(`[data-filter="the-golden-rules"]`, "the-golden-rules");
    } else {
        addActiveClass(`[data-filter="les-regles-d-or"]`, "les-regles-d-or");
    }

    setTimeout(function () {
        var banner = document.querySelector(".banner_nav");

        banner.style.display = "none";
    }, 500);

    window.onload = showElementOfUrlId;
}

function addActiveClass(selector, id) {
    const switchBtn = document.getElementById(id);

    if (switchBtn) {
        $(".btn-switch").removeClass("active");

        switchBtn.classList.add("active");
        const switchSlider = document.querySelector(
            '.switch-slider[data-filter="' + id + '"]'
        );
        $(".switch-slider").removeClass("active");
        switchSlider.classList.add("active");
    }
}

// _app override to avoid slide Down/Up
function toggleFiltersMenu() { }

function getWishlistProducts() {
    let array_elements = document.querySelectorAll("a[data-productid]");
    let array_ids = [];
    if (typeof array_elements != "undefined" && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute("data-productid");
            if (id != "" && id != "undefined") {
                array_ids.push(id);
            }
        }
        let data = JSON.parse(JSON.stringify(Object.assign({}, array_ids)));
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_all_wishlist.php",
            data: data,
            success: function (response) {
                if (response) {
                    if (response && typeof response != 'undefined' && response.length > 0 && (typeof response == JSON)) {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $(
                                'a[data-productid = "' +
                                array_product_id[product_wishlist]
                                    .fk_produit_id +
                                '"]'
                            ).addClass("existToWishlistButton");
                            $(
                                'a[data-productid = "' +
                                array_product_id[product_wishlist]
                                    .fk_produit_id +
                                '"]'
                            ).attr(
                                "data-wishlistproductid",
                                array_product_id[product_wishlist]
                                    .wishlist_product_id
                            );
                        }
                    } else {
                        return;
                    }
                }
            },
        });
    }
}

/**
 * Check if 'element' is in viewport
 */
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

/**
 * Global .item_container slider initialization
 * isToggled => is true when from a toggle between 1 or 2 products per line (function defined in _app)
 */
function initItemSlider(isToggled) {
    if ($(".prodItemSlider, .searchItemSlider").length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) {
            // Toggle view is triggerred

            setTimeout(function () {
                $(".prodItemSlider, .searchItemSlider").each(function () {
                    if ($(this).hasClass("initialized")) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else {
            // From page load and scroll

            $(".prodItemSlider").each(function () {
                setTimeout(() => {
                    if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                        // Prevent initialized sliders to go back to the first frame during scroll

                        new Swiper($(this), {
                            roundLengths: true,
                            slidesPerView: 1,
                            loop: true,
                            preloadImages: false,
                            spaceBetween: 10,
                            lazyLoading: swiperLazy,
                            observer: true,
                            observeParents: true,
                            pagination: ".itemSliderPagination",
                        });

                        $(this).addClass("initialized");
                    }
                }, 500);
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: ".itemSliderPagination",
                    });

                    $(this).addClass("initialized");
                }
            });
        }
    }

    if ($('#list_fp_characts .swiper-slide').length) {
        new Swiper('#list_fp_characts', {
            freeMode: true,
            setWrapperSize: true,
            spaceBetween: 12.5,
            roundLengths: true,
            slidesPerView: 5.1,
            preloadImages: false,
            lazyLoading: swiperLazy,
            observer: true,
            observeParents: true,
        });
    }
}

/**
 * Load global .item_container slider initialization on window scroll
 */
window.addEventListener("scroll", function () {
    initItemSlider(false);
});

function toggleViewMobile() {
    $("div.toggleNumObjects span").toggleClass("actif");
    if ($("#oneProduct").hasClass("actif")) {
        $(".twoProducts").removeClass("actif");
        $("div.item").addClass("full");
        createCookie("nb_produits", 1, 1);
    } else {
        $("div.item").removeClass("full");
        $(".oneProduct").removeClass("actif");
        createCookie("nb_produits", 2);
    }

    initItemSlider(true);
}

/* STORE LOCATOR */
function creerLigneListe(
    cpt,
    id,
    nom,
    adresse,
    ville,
    cp,
    pays,
    currently_open,
    horaire_today,
    distance,
    marqueur,
    picture_filtre,
    magasinId,
    stock,
    stockStatus,
    stock_quantity,
    clientId,
    favorite,
    beContacted,
    flag_available,
    url,
    shop_filter,
    telephone,
    query,
    lat,
    lng
) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement("li");
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = "elem-list-store";

    if (lat != "" && lng != "" && !$("body.product_page").length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    if (favorite == "1") {
        choose_fav = Translator.translate("actual_favorite_store");
    } else {
        choose_fav = Translator.translate("choose_as_favorite_store");
    }

    /***** Product stock infos *****/
    if (stock_quantity == "undefined" || stock_quantity == "") {
        stock_status_msg = "unknow_stock";
    } else {
        var in_stock = stock_quantity >= 6 && stock_quantity <= 9999;
        var limited_stock = stock_quantity >= 1 && stock_quantity <= 5;
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = "";
        if (in_stock) {
            stock_status_msg = "in_stock";
        }
        if (limited_stock) {
            stock_status_msg = "limited_stock";
        }
        if (not_in_stock) {
            stock_status_msg = "not_in_stock";
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = "to_be_confirmed_stock";
        }
        if (can_be_ordered_stock) {
            stock_status_msg = "can_be_ordered_stock";
        }
    }

    var info_horaire_today = !horaire_today
        ? Translator.translate("store_closed")
        : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_closed";
    var ligneListe =
        '<div class="elem_list_contents">' + '<div class="nom_store">';
    if (distance) {
        ligneListe +=
            '<h2 data-gp-noloc="node" class="title">' +
            nom +
            '<p class="distance">' +
            "(" +
            distance +
            "km)" +
            "</p></h2>";
    } else {
        ligneListe += '<h2 data-gp-noloc="node" class="title">' + nom + "</h2>";
    }
    ligneListe += "</div>" + '<div class="content_store">';

    if ($("body.product_page").length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url
        }'">`;
    } else {
        ligneListe += '<div class="detail_store">';
    }
    ligneListe +=
        '<div data-gp-noloc="subtree" class="col-1">' +
        '<span class="rue_store">' +
        adresse.toLowerCase() +
        "</span>" +
        '<span class="ville_store">' +
        cp +
        " " +
        ville.charAt(0).toUpperCase() +
        ville.slice(1).toLowerCase() +
        " - " +
        '<span class="pays_store">' +
        pays +
        "</span>" +
        "</span>" +
        '<span class="tel_store">' +
        telephone +
        "</span></div>";

    var is_page_product = $("body.product_page").length == 1;

    if (
        (info_horaire_today !== "" && currently_open) ||
        (!currently_open && horaire_today)
    ) {
        ligneListe +=
            '<div class="col-2"><p class="horaire_today puce_' +
            class_horaire_today +
            '">' +
            info_horaire_today +
            "</p></div>";
    }

    ligneListe += "</div>";
    if (document.getElementById("produit_id")) {
        /***** Product stock infos msg *****/
        if (stock != "undefined") {
            ligneListe +=
                '<p class="stock_status ' +
                stock_status_msg +
                '">' +
                Translator.translate(stock_status_msg + "_msg") +
                "</p>";
        }
    }

    if (is_page_product) {
        if (beContacted == 1) {
            if ($("body.product_page.mobile").length) {
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChangeMobile(' +
                    id +
                    ')">' +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChange(\'' +
                    id +
                    "','" +
                    nom_mag +
                    "')\">" +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            }
        } else {
            ligneListe += "</div>" + "</div>";
        }
    }

    isFavorite = favorite ? "favorite_store_js" : "";
    ligneListe +=
        '<a href="javascript:;" class="addfavorite" id="store' +
        magasinId +
        '" onclick="addToMyFavorite(\'' +
        magasinId +
        "', '" +
        clientId +
        "', '" +
        favorite +
        "', '" +
        escape(info_horaire_today) +
        "','" +
        currently_open +
        "','" +
        escape(horaire_today) +
        "','" +
        class_horaire_today +
        "','" +
        stock +
        "','" +
        stockStatus +
        "'); return false;\">";
    ligneListe +=
        '<span class="choose_fav_label" id="favShop-' +
        magasinId +
        '"><span class="' +
        isFavorite +
        '">' +
        choose_fav +
        "</span></span></a>";

    if (!is_page_product) {
        ligneListe +=
            '<div class="store_more"><a onclick="createDataLayer(' +
            id +
            ')" href="' +
            path_relative_root +
            url +
            '">' +
            Translator.translate("afficher_horaires") +
            "</a>" +
            "</div>";
    }

    ligneListe += "</div>" + "</div>" + "</div>";

    li.innerHTML = ligneListe;

    li.addEventListener("click", function () {
        google.maps.event.trigger(marqueur, "click");
    });

    return li;
}

function creerMarqueur(
    point,
    nom,
    adresse,
    ville,
    cp,
    pays,
    horaire,
    telephone,
    email,
    site,
    image_mag,
    type_mag,
    cpt,
    magasin_id,
    url,
    distance,
    horaire_today,
    currently_open
) {
    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(38, 45),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    var shadow = new google.maps.MarkerImage(
        path_relative_root + "img/indic_carte_ombre.png",
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0)
    );
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
    });

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";
    var currently_open_info = currently_open
        ? Translator.translate("store_opened")
        : Translator.translate("store_closed");

    var cpt_recherche = typeof cpt != "undefined" ? "(" + cpt + ") - " : "";

    if (distance) {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            '</a><span class="dist">(' +
            distance +
            " km)</span>";
    } else {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            "</a></div>";
    }

    ligneListe +=
        '<div class="adresse">' +
        adresse.toLowerCase() +
        "<br />" +
        cp +
        " " +
        ville.toLowerCase() +
        "<br />" +
        pays.toLowerCase() +
        "</div>";

    if (telephone != "")
        ligneListe += '<div class="coord">' + telephone + "</div>";

    if (site != "")
        if (!http.test(site)) {
            ligneListe +=
                '<a href="http://' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        } else {
            ligneListe +=
                '<a href="' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        }

    if (horaire != "")
        if (class_horaire_today == "store_open") {
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                "</span>" +
                info_horaire_today +
                '</div><div class="horaire_info">' +
                horaire +
                "</div>";
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                '</span></div><div class="horaire_info">' +
                horaire +
                "</div>";
        }
    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate(
        "go_to_store"
    )}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate(
        "voir_magasin"
    )}</a></div>
        </div>`;
    // Add marker image inside tooltip
    ligneListe += `<div class="store_marker"><img src="${image.url}"/></div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id;

    markersArray.push(marqueur);

    return marqueur;
}

function addToMyFavorite(
    magasin_id,
    client_id,
    favorite,
    info_horaire_today,
    currently_open,
    horaire_today,
    class_horaire_today,
    stock,
    stockStatus
) {
    event.preventDefault();
    if (client_id == 0) {
        var connect_link = path_relative_root + create_link("connexion_login");
        alert(
            '<div class="box_txt_wishlist selected_mag"><h2>' +
            Translator.translate("add_store_login_error_title") +
            "</h2><br>" +
            Translator.translate("add_store_login_error_text") +
            '<br><button class="button primary" onclick="favStoreRedirectLogin()">' +
            Translator.translate("vgt_connexion") +
            " </button></div>"
        );
    } else {
        var act = "add";

        $.ajax({
            url: path_relative_root + "ajax_manage_favorite_store.php",
            type: "post",
            dataType: "html",
            data:
                "act=" +
                act +
                "&magasin_id=" +
                magasin_id +
                "&client_id=" +
                client_id,
            success: function (response) {
                response = JSON.parse(response);
                var getCustomerFavoriteStore = document.querySelector(
                    "#customerFavoriteStore"
                );
                var customerFavoriteStore = false;
                if (getCustomerFavoriteStore) {
                    customerFavoriteStore = customerFavoriteStore.value;
                }
                var shopName = document.querySelector(".mag_name_fav");
                var shopStock = document.querySelector(".mag_stock_fav");
                var shopHours = document.querySelector(".mag_hours");
                var shopTown = document.querySelectorAll(".mag_ville");
                var shopStreet = document.querySelector(".mag_rue");
                var shopCountry = document.querySelector(".mag_pays");
                var shopCp = document.querySelector(".mag_cp");

                var noStoreSelected =
                    document.querySelector("#no_store_selected");
                var noStoreSelectedHidden = document.querySelector(
                    "#no_store_selected_hidden"
                );

                var shopList = document.querySelector(".liste_distributeur");

                var customerHeartFav = document.querySelector(
                    "#heartFav-" + magasin_id
                );
                var allHeartFav = document.querySelectorAll(".heartFav");

                var currentFavStore =
                    document.querySelector("#currentFavStore");
                var hiddenProductFavStore = document.querySelector(
                    "#hiddenProductFavStore"
                );
                var noFavShop = document.querySelector("#noFavShop");

                if (allHeartFav) {
                    for (var i = 0; i < allHeartFav.length; i++) {
                        allHeartFav[i].className = "heartFav";
                    }
                }
                if (hiddenProductFavStore && noFavShop) {
                    noFavShop.style.display = "none";
                    hiddenProductFavStore.style.display = "flex";
                }

                if (customerHeartFav) {
                    customerHeartFav.className = "heartFav";
                    customerHeartFav.className = "selected";
                }

                if (shopList) {
                    shopList.className += " withfav";
                }
                if (
                    noStoreSelected &&
                    noStoreSelectedHidden &&
                    !currentFavStore
                ) {
                    noStoreSelected.style.display = "none";
                    noStoreSelectedHidden.style.display = "block";
                }

                if (shopName) {
                    shopName.innerHTML = response.store.magasin_nom;
                }

                if (shopTown) {
                    for (var i = 0; i < shopTown.length; i++) {
                        var town = shopTown[i];
                        town.innerHTML = response.store.magasin_ville;
                    }
                }
                if (shopStreet) {
                    shopStreet.innerHTML = response.store.magasin_adresse;
                }
                if (shopCp) {
                    shopCp.innerHTML = response.store.magasin_cp;
                }
                if (shopCountry) {
                    shopCountry.innerHTML = response.store.magasin_pays;
                }
                if (stock != "null") {
                    shopStock.innerHTML = Translator.translate(
                        stock.slice(0, -3)
                    );
                    shopStock.setAttribute("data-status", stockStatus);
                }

                if (shopHours) {
                    if (
                        (info_horaire_today !== "" && currently_open) ||
                        (!currently_open && horaire_today)
                    ) {
                        shopHours.innerHTML =
                            '<span class="horaire_today"><span class="puce ' +
                            unescape(class_horaire_today) +
                            '"></span> ' +
                            unescape(info_horaire_today) +
                            "</span>";
                    } else {
                        shopHours.innerHTML = "";
                    }
                }

                var storeClient = document.getElementById(
                    "favShop-" + magasin_id
                );
                var allStore =
                    document.getElementsByClassName("choose_fav_label");
                var countAllStore = allStore.length;

                for (var i = 0; i < countAllStore; i++) {
                    allStore[i].innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }

                if (response.status == "add") {
                    storeClient.innerHTML =
                        '<span class="favorite_store_js">' +
                        Translator.translate("actual_favorite_store") +
                        "</span>";
                } else if (response.status == "delete") {
                    storeClient.innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }

                if ($('#pdvBox').hasClass("deployed") && act == "add") {
                    $('#pdvBox').removeClass("deployed");
                    $('#pdvBox').removeClass("actif");
                    $('#shade').removeClass("actif");
                    location.reload();
                }
            },
        });
    }
    return false;
}

// popup PDV
function showpdv() {
    var shade, modbox;
    $.ajax({
        type: "post",
        url: path_relative_root + create_link("ajax_search_shop"),
        success: function (res) {
            if (res) {
                shade = document.querySelector("#shade.modal_shade");
                modbox = document.getElementById("pdvBox");

                // Make sure modbox will show on top of shad
                $(shade).after(modbox);

                // Inject store locator into modbox
                $(".store_locator").html(res);

                // magasin_gmap.js
                initialize();

                // Show modbox and make sure closing routine won't fire more than once
                $(modbox).addClass("actif");
                $(shade).addClass("actif");

                $(shade).on("click touch", closepdv);
                $("#pdv_load").removeClass("loading");
            }
        },
    });
}

function closepdv(from) {
    var shade = document.querySelector("#shade.modal_shade");
    var modbox = document.getElementById("pdvBox");

    $(modbox).removeClass("actif");
    $(shade).removeClass("actif");

    $(shade).on("click touch", closepdv);

    if (from == "fiche produit") {
        setTimeout(function () {
            $("#content_pdvBox_new").hide();
            $("#content_pdvBox").show();
            $(modbox).removeClass("showForm");
            $(modbox).removeClass("thanks");
        }, 200);
    }
}

/* ------------------------------ ACHAT EXPRESS ------------------------------ */
function openColorAchatExpressV2() {
    var $div_color = $(this.form).find(".productColorFieldset");
    var $div_size = $(this.form).find(".productSizeFieldset");
    var $div_alert_stock = $(this.form).find(".bloc_add_alert");
    var lightbox = $(this.form).find(".achat_express");
    var lightboxSize = $(this.form).find(".full.btnAddBasketWrapper.noSizeAvailable");

    /* Hiding previous selections */
    $div_alert_stock.hide();
    $div_size.removeClass("actif");

    /* Display new selection */
    $("#shade").addClass("isExpress").addClass("visible");
    $("#shade").on("click touch", function () {
        $("#shade").removeClass("isExpress").removeClass("visible");
        $div_color.removeClass("actif");
        lightbox.hide();
        lightboxSize.hide();
    });

    $div_color.addClass("actif");
    lightbox.show();
    lightboxSize.hide();
}

function openSizeAchatExpressV2() {
    var form = this.form;
    var $div_color = $(form).find('.productColorFieldset');
    var $div_size = $(form).find('.productSizeFieldset');
    var size_id = $(form).find('input[name="tailleProd"]').val();
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    var div_alert_stock_title = $(this.form).find('.alert_stock');
    var div_alert_stock_txt = $(this.form).find('.bloc_add_alert_form');
    var lightbox = $(form).find('.achat_express');
    var lightboxSize = $(form).find('.full.btnAddBasketWrapper.noSizeAvailable');
    /* Hiding previous selections */
    $div_alert_stock.hide();
    // on a un soucis sur le titre qui disparait sur toutes les alerts stock après en avoir enclenché 1, si le formulaire est affiché on force l'affichage du titre sinon c'est qu'on affiche la confirmation et on ne l'affiche pas
    if (div_alert_stock_txt.length && !div_alert_stock_txt[0].getAttribute("style")) {
        div_alert_stock_title.show();
    }
    $div_color.removeClass('actif');
    if ($('#displaySizeAlert').hasClass('open')) {

        $('#displaySizeAlert').removeClass('open');
    }

    if (size_id !== 'none') {

        form.elements['itm_size'].checked = false;
    }

    if (!$("#shade").hasClass('visible')) {
        $("#shade").addClass("isExpress").addClass("visible");
    } else {

        $("#shade").addClass("isExpress");
    }

    $("#shade").on('click touch', function () {
        $("#shade").removeClass("isExpress").removeClass("visible");
        $("#displaySizeAlert").removeClass("open");
        $(".productSizeFieldset").removeClass("actif");
        $(".bloc_add_alert").hide();
        $div_size.removeClass('actif');
        lightbox.hide();
        lightboxSize.hide();

        if ($("body").hasClass("product_page") && $(form).hasClass('active')) {
            var idProduct = $(form).find('input[name="produit_id"]').val();
            var locationParent = $('#item_entretien .swiper-slide[data-produit-id*="'+idProduct+'"]');
            var idProductSuff = $(form).find('input[name="suff"]').val();
            var formId = 'prod_info_' + idProductSuff;

            form.classList.remove('active');

            $(locationParent).find('.wrap_rolloverproduit > .wrap_rolloverproduit').append($('.ranges_carousel_wrapper + #' + formId));
        }
    });

    // here we put 9 which corresponds to the one-size product
    if (size_id === 'none' || size_id == 9 ) {
        sendFormUpdateCart.call(form);
    } else {
        $div_size.addClass('actif');
        lightbox.show();
        lightboxSize.hide();

        if ($("body").hasClass("product_page") && $(form).parents('#item_entretien').length) {
            var idProductSuff = $(form).find('input[name="suff"]').val();
            var formId = 'prod_info_' + idProductSuff;

            // Find the form using the generated formId and append it to the accordion_contents div
            var formElement = $('#' + formId); // Select the form by its id

            if (formElement.length > 0) {
                $('#item_entretien .accordion_contents').append(formElement);

                formElement.addClass('active');
            }
        }
    }

    var product_id = parseInt($(form).find('input[name=produit_id]').val());

    if (array_colisages[product_id] == undefined) {
        var array_product_ids = [];
        var array_products = $(document).find('input[name=produit_id]');

        Object.values(array_products).forEach(function (value) {
            if (value.id && value.id.indexOf('produit_id_') >= 0 && array_colisages[$(value).val()] == undefined) {
                array_product_ids.push($(value).val());
            }
        });

        if (array_product_ids.length > 0) {
            var data = {produit_id: array_product_ids};

            if (isGlobalEActive) {
                data.warehouse_only = 1;
            }

            $.ajax({
                url: path_relative_root + create_link('ajax_get_colisages_states_by_color_size'),
                type: 'post',
                data: data,
                async: false,
                success: function (res) {
                    try {
                        res = $.parseJSON(res);
                    } catch (e) {
                        console.error("parseJSON");

                        return;
                    }

                    if (res.success && res.result) {
                        Object.entries(res.result).forEach(function(entry) {
                            const [idProduct, objProduct] = entry;
                            array_colisages[idProduct] = objProduct;
                        });
                    }
                }
            });
        }
    }

    if (array_colisages[product_id] != undefined) {
        var sizes_tab = array_colisages[product_id][$(form).find('input[name=couleurProd]').val()];

        var size_box, is_disabled;
        var has_stock = false;
        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $(form).find('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];

                if (!is_disabled) {
                    has_stock = true;
                    size_box.removeClass('no_stock');
                    size_box.removeClass('disabled');
                } else {
                    size_box.addClass('no_stock');
                    size_box.addClass('disabled');
                }
            }
        }
    }

    if (isGlobalEActive) {

        $('.no_stock_entrepot').each(function () {

            if ($(this).html() == '') {

                if (!$(this).prev().hasClass('no_stock')) {

                    $(this).prev().addClass('no_stock');
                    $(this).prev().show();
                }
            } else {

                $(this).show();
            }
        });
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;

    var idObj = getCurrentIdRayon();
    var bloc_page = page - 1;

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type === "product") {
        generateNewBlocProd(
            bloc_page,
            null,
            idObj,
            null,
            null,
            fromScrollBottom,
            fromScrollTop,
            true
        );
    } else {
        generateNewBlocSearch(
            document.getElementById("nb_prod").value,
            bloc_page,
            null,
            fromScrollBottom,
            fromScrollTop
        );
    }
}

function paginate(page) {
    var form = document.getElementById("filters_form"),
        total_element = parseFloat($("#totalElems").val()),
        nb_prod = parseFloat($("#nb_prod").val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        nb_prod_default = parseFloat($("#nb_prod_default").val()),
        pagination = $(".wrap_flitre_pager"),
        see_all = parseFloat($("#see_all").val()),
        thisPage = parseInt(page / nb_prod),
        initialPage = thisPage;

    page = page || 1;

    $("#page").change(function () {
        page = parseFloat($("#page").val());
        thisPage = parseInt(page / nb_prod + 1);
    });

    $.ajax({
        url: path_relative_root + "ajax_reload_pagination.php",
        type: "get",
        data: {
            page: page,
            link: "paginationGoToPage()",
            nb_total_page: nb_total_page,
        },
        success: function (res) {
            var productLoaded = $(
                "#scroll_items .item_container:not(.push)"
            ).length;
            var percentLoaded = (productLoaded * 100) / total_element;

            if (productLoaded < total_element) {
                pagination.each(function () {
                    if (see_all === 0) {
                        this.innerHTML =
                            res +
                            '<div class="pagerNav end_pagi"><div class="nbProdDisplayed">' +
                            Translator.translate("pagerTxt1") +
                            " " +
                            productLoaded +
                            " " +
                            Translator.translate("pagerTxt2") +
                            " " +
                            total_element +
                            '</div><div class="loadedGauge"><span style="width: ' +
                            percentLoaded +
                            '%;"></span></div>' +
                            '<div class="loader_scroll">' +
                            '<button class="see_all_product button primary"><span>' +
                            Translator.translate("see_more_products") +
                            "</span></button>" +
                            '<div class="button primary loader"></div>' +
                            "</div></div>";
                    } else {
                        this.innerHTML = "";
                    }
                });
            }

            $(".loader_scroll .see_all_product").on("click", function () {
                $(this).addClass("loading");

                if (
                    $(".dynasearchwrapper").length === 0 &&
                    $('#scroll_items .item:not(".push")').length < total_element
                ) {
                    if (initialPage >= 2 && thisPage + 1 === initialPage) {
                        paginationGoToPage(thisPage + 2, true, false);
                    } else {
                        paginationGoToPage(thisPage + 1, true, false);
                    }
                }

                initDailymotionVideos();
            });

            if (productLoaded >= total_element) {
                $(".see_all_product").hide();
            }

            initDailymotionVideos();
        },
    });
}

function generateNewBlocProd(
    page,
    type,
    idObj,
    nb_product,
    btn,
    fromScrollBottom,
    fromScrollTop,
    fromPagination = false
) {
    var form =
            this instanceof HTMLFormElement
                ? this
                : document.getElementById("filters_form"),
        page = page !== undefined ? page : form.getValue("page", parseFloat),
        type = type || form.getValue("type"),
        idObj = idObj || form.getValue("idObj"),
        nb_product = nb_product || form.getValue("nb_prod", parseFloat),
        startPage = parseInt($("#page_start").val()),
        is_from_page = false,
        key,
        output = {};

    if ($(".see_all")) {
        $(".see_all").addClass("loading");
    }

    if (fromScrollBottom || fromScrollTop) {
        $("#page_start").val(startPage + 1);

        $("#initial_page").val(page + 1);
    }

    output = triggerFilter(
        page,
        type,
        idObj,
        nb_product,
        "filters_form",
        fromPagination
    );

    if ($("#oneProduct").hasClass("actif")) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    output.object_id = this.object_id.value;

    if (typeof id_category_super != "undefined") {
        output.id_category_super = id_category_super;
    }

    if ($("#is_marque").val()) {
        output.is_marque = $("#is_marque").val();
        output.promo = $("#brand_id").val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + "ajax_reload_products.php",
        type: "get",
        data: output,
        success: function (res) {
            var old_elm = document.querySelector(".list_item");
            var content, itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                (btn.nextElementSibling.classList.contains("btn_tunnel") ||
                    btn.nextElementSibling.classList.contains("loader"))
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            // This block of code updates the SEO-related link elements in the HTML document.
            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang='en']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if ($(".rayonCategSlider").length && output.object_id) {
                $(".rayonCategSlider .menuitem").removeClass("actif");
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $(".rayonCategSlider .swiper-slide").each(function (i, obj) {
                    if ($(obj).hasClass("actif")) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $(".rayonCategSlider .menuitem").removeClass("actif");
            }

            if (content !== "") {
                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.innerHTML = "";
                }

                document.getElementById("nbr_items").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $("#scroll_items .item_container:first");
                    var curOffset =
                        firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML("afterbegin", content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else {
                    old_elm.insertAdjacentHTML("beforeend", content);
                }

                if (one_product) {
                    $("div.item_container").addClass("full");
                }

                if (parseFloat($("#filters_menu").css("right")) === 0) {
                    toggleMenu("filters", "right");
                }

                if (window.lazyload || typeof lazyload != "undefined") {
                    lazyload.init();
                }
            }
        },
        complete: function () {
            $("#totalElems").trigger("change");
            $("#bottom_reached").val("0").trigger("change");
            $("#page").trigger("change");

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate(
                        {
                            scrollTop: $(window).scrollTop() + 10,
                        },
                        200
                    );
                }, 250);
            }

            if (fromScrollTop || fromScrollBottom) {
                $("#is_loading").val("0");

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($("body.category").length) {
                    var origin = $(
                            "#scroll_items .item_container:not(.push)"
                        ).find("img.watched"),
                        target = $("span.ill_img"),
                        targetHeight = origin.height();
                }
            }

            setTimeout(function () {
                if (
                    $(btn).closest(".drawer").length &&
                    $(btn).closest(".drawer").attr("id")
                ) {
                    var menuId = $(btn)
                        .closest(".drawer")
                        .attr("id")
                        .replace("_menu", "");
                    toggleMenu(menuId);
                }
            }, 200);

            let total_element = parseInt(document.getElementById("totalElems").value);

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $(
                ".list_item .item_container:not(.push)"
            ).length;
            var percentLoaded =
                (productLoaded * 100) / total_element;

            if (productLoaded > total_element) {
                $(".nbProdDisplayed").html(
                    Translator.translate("pagerTxt1") +
                    " " +
                    productLoaded +
                    " " +
                    Translator.translate("pagerTxt2") +
                    " " +
                    productLoaded
                );
            } else {
                $(".nbProdDisplayed").html(
                    Translator.translate("pagerTxt1") +
                    " " +
                    productLoaded +
                    " " +
                    Translator.translate("pagerTxt2") +
                    " " +
                    total_element
                );
            }

            $(".loadedGauge").html(
                '<span style="width: ' + percentLoaded + '%;"></span>'
            );

            $(".see_all_product").removeClass("loading");

            if (productLoaded >= total_element) {
                $(".loader_scroll").hide();
                $(".see_all_product").hide();
            }
        },
    });
}

// Initialisation des JS pour la recherche V2
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);
}

function lightboxAlertStock(id, type_alert, produit_page_id) {
    if (typeof produit_page_id == "undefined") {
        var produit_id = $('input[name="produit_id"]').val();
    } else {
        var produit_id = produit_page_id;
    }

    var $form, data;
    if (!$("#lightbox_stock_alert").hasClass("open")) {
        $form = $("#product_selects");
        data = $form.serialize();
        // Donc dans le cas où on ne le trouve pas on cherche sur les id des couleur et de la taille
        if ($form.length == 0) {
            // Si on a pas de product_selects alors on va chercher les valeur qu'il nous manque (couleur et taille)
            data += "&tailleProd=" + $("#prod_taille").val();
            data += "&couleurProd=" + $("#prod_couleur").val();
        }
    } else {
        $form = $("#form_colisage");
        data = $form.serialize();
    }

    type = "couleur";
    var $input = $("#" + id, $form); // ID Champs Clique
    var $from = id.split("_")[0];
    var $hidden = $("#" + type + "Prod", $form);

    var $select = $("#ligne_" + type);
    var $list_inputs = $(".select_input", $select);
    var $list_labels = $(".select_label", $select);

    // Les info supplémentaire
    data += "&produit_id=" + produit_id;
    data += "&from=nothing";
    data += "&type_alert=" + type_alert;

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax_lightbox_alert.php",
        data: data,
        success: function (response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            if (response.success == "ok") {
                var datas = response.datas;
                var $html = $(datas.html);
                $("#lightbox_stock_alert").html($html);
            } else {
                $("#btn_error").fadeIn();
            }
        },
        complete: function () {
            $("#bloc_btn_loader, .loader").fadeOut(300);
            if (typeof produit_page_id == "undefined") {
                document
                    .getElementById("lightbox_stock_alert")
                    .classList.add("open");
                modBoxOpen.call(document.getElementById("displaySizeAlert"));
            } else {
                modBoxOpen.call(
                    document.getElementById("btn_add_alert_" + produit_id)
                );
            }
        },
    });
}

// Verify phone number
function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                        fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

/**
 * Add new payement card in account page
 */
$(document).on(
    "click touch",
    ".account_payment_options .addNewCard",
    function () {
        $(".wrapper_form").removeClass("displayed");
        $("#psp_form").addClass("displayed");
    }
);

/**
 * Bank thanks create account button loader
 */
$(document).on("click touch", ".bankThanksBtn", function () {
    $(this).addClass("loading");
});

function mySubmit(pType) {
    $("#btn_cmd_valid").next(".loader").addClass("loading");
    var form = document.getElementById("formPanier");

    setTimeout(function () {
        form.submit();
    }, 500);
}

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form);
    var type = value("type_tri"),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById("wrapper_couleur_filtre").innerHTML =
                filterformColors;
            document.getElementById("wrapper_taille_filtre").innerHTML =
                filterformSize;
        }

        resetExtraFilters();
        form.reset();
        $("#filters_menu input[checked]")
            .removeAttr("checked"); //force suppression de tous les filtres checked.
    }
    $("legend.open", form).each(function () {
        toggleFiltersMenu.call(this);
    });

    // Check obj type
    if (form.sscat) {
        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value("sscat");

        if (idObjTmp && idObjTmp.length > 0) {
            type = "obj";
            $("#object_id").val(idObjTmp);
        } else {
            type = "subcat";
        }
    }

    $("#type_tri").val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (
        btn.nextElementSibling &&
        btn.nextElementSibling.classList.contains("button")
    ) {
        btn.nextElementSibling.style.display = "block";
    }

    // Get new elements
    if (from_type === "product") {
        generateNewBlocProd.call(form, 0, type, idObj, value("nb_prod"), btn);
    } else {
        generateNewBlocSearch.call(form, value("nb_prod"), 0, btn);
    }

    return false;
}

function changeVisu(field, path, evt) { }

function checkProductInWishlist() {
    if (typeof button_add_to_wishlist_initial_click_event == "undefined") {
        button_add_to_wishlist_initial_click_event = $(
            "#addToWishlistButton"
        ).attr("onclick");
    }
    if (typeof button_add_to_wishlist_initial_label == "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    let productId = $("#produit_id").val();
    let checkColor = $(".prodColor");
    let checkSize = $("#prod_taille");
    let colorSelected =
        checkColor.is("input[type=radio]") && checkColor.is(":checked")
            ? checkColor.val()
            : "";
    let sizeSelected = checkSize.length ? checkSize.val() : "";

    if (
        typeof productId != "undefined" &&
        colorSelected !== "" &&
        sizeSelected !== ""
    ) {
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_wishlist.php",
            data: {
                product_id: productId,
                size_id: sizeSelected,
                color_id: colorSelected,
            },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            },
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {
    $("#addToWishlistButton").attr(
        "onclick",
        button_add_to_wishlist_initial_click_event
    );

    if (in_wishlist) {
        $("span", "#addToWishlistButton").html(
            Translator.translate("exist_to_wishlist_button_label")
        );
        $("#addToWishlistButton")
            .removeClass("addToWishlistButton")
            .addClass("existToWishlistButton");
        $("#addToWishlistButton").removeClass("heart").addClass("heart_b");
    } else {
        $("span", "#addToWishlistButton").html(
            Translator.translate("wishlist")
        );
        $("#addToWishlistButton")
            .removeClass("existToWishlistButton")
            .addClass("addToWishlistButton");
        $("#addToWishlistButton").removeClass("heart_b").addClass("heart");
    }
}

function triggerProductDatalayerPush(prod_id, url) {
    if (typeof productlist_gondole !== 'undefined') {
        for (var i = 0; i < productlist_gondole.length; i++) {
            prod = productlist_gondole[i];
            if (prod.id == prod_id) {
                window.list = prod['list'];
                delete prod['list'];
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': { 'list': list },      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': 'function() { document.location = url}',
                });
            }

        }
    }
}

// Datalayer v2
function dataLayer_associated_product() {
    if (window.$('#carousel_wrapper')) {
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        var productObj = null;
        if (product_id !== '') {
            if (typeof data_layer_products != 'undefined' && data_layer_products.length > 0) {
                data_layer_products.forEach(function (item) {
                    if (item.id == product_id) {
                        productObj = item;
                    }
                })
            } else if (typeof data_layer_products_aimerez != 'undefined') {
                var objs = Object.keys(data_layer_products_aimerez);
                var values = Object.values(data_layer_products_aimerez);

                for (var i = 0; i < objs.length; i++) {
                    if (values[i].id == product_id) {
                        productObj = values[i];
                    }
                }
            }
            var prod = productObj;

            if (productObj !== null && productObj !== undefined) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': { 'list': productObj['category'] },      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }

}

function initToggleCartTypeBtn() {

    if ($(".toggleCartTypeBtn").length) {
        let toggleCart = $(".toggleCartTypeBtn");
        let cartContent = $(".cart_main_table.basket");
        let wishContent = $(".cart_main_table.wishlist");
        let cartSummary = $("#tunnel_right_col");

        toggleCart.on("click", function () {
            toggleCart.removeClass("actif");
            $(this).addClass("actif");
            $(".cart_main_table").removeClass("displayed");

            if ($(this).hasClass("basket")) {
                cartContent.addClass("displayed");
                cartSummary.removeClass("noDisplay");
            } else {
                wishContent.addClass("displayed");
                cartSummary.addClass("noDisplay");
            }
        });
    }

}

var checkCount = 0;

function onLoadProductForm(hide_bookinstore, date_arrivage)
{
    var produit_id = $('#produit_principal').val();
    var data = {produit_id: new Array(produit_id)};

    // native "ready" functions are way too slow
    var formInterval = window.setInterval(function () {

        if (isGlobalEActive || document.readyState == "complete") {
            clearInterval(formInterval);
        }

        if (isGlobalEActive) {
            data.warehouse_only = 1;
        } else if (document.readyState != "complete") {
            return false;
        }

        $.ajax({
            url: path_relative_root + create_link('ajax_get_colisages_states_by_color_size'),
            type: 'post',
            data: data,
            success: function (res) {
                try {
                    res = $.parseJSON(res);
                } catch (e) {
                    console.error("parseJSON");

                    return;
                }

                if (res.success && res.result) {
                    var has_stock = false;
                    var length_item_color = $('.product_form  [name="itm_colour"]').length;

                    if (length_item_color) {
                        var sizes_tab = res.result[produit_id][$("input[name=itm_colour]:checked").val()];
                    } else {
                        var sizes_tab = res.result[produit_id][0];
                    }

                    var size_box, is_disabled;
                    for (size_id in sizes_tab) {
                        if (sizes_tab.hasOwnProperty(size_id)) {

                            if (sizes_tab[size_id]) {
                                has_stock = true;
                                $('#size_' + size_id).removeClass('disabled');
                            } else {
                                $('#size_' + size_id).addClass('disabled');
                            }
                        }
                    }

                    if (has_stock) {
                        $("#add_basket_wrapper").show();
                        $("#link_book_in_store").show();
                        $(".stock_check").show();
                        $("#bloc_add_alert").hide();
                    } else {
                        $("#add_basket_wrapper").hide();
                        $(".stock_check").hide();
                        if (hide_bookinstore == 1) { // if we want to hide CTA "book in store"
                            $("#link_book_in_store").hide();
                        }
                        if (date_arrivage == false || date_arrivage.length > 0 && date_arrivage != "0000-00-00 00:00:00") {
                            $("#bloc_add_alert").show();
                        }
                    }
                }
            }
        });
    }, 500);
}

if ($('body').hasClass('contact_faq') && $('.w-input-element[name="form_service"]').length) {
    showInputCommandeInContactPage();
}

if ($(".full.btnAddBasketWrapper.noSizeAvailable").length > 0) {
    $(".full.btnAddBasketWrapper.noSizeAvailable" ).append( `<div class="lightbox_buttons">
            <div class="form_submit">
                <button class="button cancel" onclick="PopUpNotAvailable();">
                    <span>${Translator.translate('close')}</span>
                </button>
            </div>
        </div>`
    );
}

function PopUpNotAvailable() {
    // Select the element with the class
    var element = $( '.full.btnAddBasketWrapper.noSizeAvailable' );

    // Check if element exists
    if ( ( element ).length > 0 ) {
        // Call modBoxClose function in the context of the selected element
        ( element ).css( "display", "none" );
        $( "#shade" ).removeClass( "isExpress" ).removeClass( "visible" );
    }
}

// Get product data
function getProductDetail(productId) {

    let checkColor = $("#item_add_form .prodColor");
    let checkSize = $("#item_add_form #prod_taille");
    let colorSelected = checkColor.is("input[type=radio]") && checkColor.is(":checked") ? checkColor.val() : "";
    let sizeSelected = checkSize.length ? checkSize.val() : "";

    let data = {
        produitId: productId,
        colorId : colorSelected,
        sizeId : sizeSelected,
        json: 'json'
    };

    $.ajax({
        url: path_relative_root + create_link('ajax_product_data'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            // Set event ID for product detail dataLayer
            if (typeof(res) == 'object') {
                pushIntoDatalayer(res.dataLayerData.productDetails);
            }
        }
    });
}

// Function push into DataLayer
function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

// wonky fix for SW-14107... 🥴
$('body').on('click', '#cancel_button', function () {
    $('#cancel_button+.loader').addClass('loading');
    setTimeout(function() {
        $('#cancel_button+.loader').removeClass('loading');
    }, 2000);
});

function toggleAccountMenuExtra() {
    setTimeout(() => {
        if($('#main_menu_btn').hasClass('menu_open')) {
            $('#main_menu_btn').removeClass('menu_open');
            $('#left_menu').removeClass('open');
            shad.classList.add('forMenu');
        }
    }, 100);
}

function toggleMenuExtra() {
    setTimeout(() => {
        if($('#user_btn').hasClass('active')) {
            $('#user_btn').removeClass('active');
            $('#account_menu').removeClass('active');
            shad.classList.add('forMenu');
        }
    }, 100);
}

function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '_' + id + itm_rr_id;

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('#bloc_add_alert' + elem + ' .alert_stock').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                $('#bloc_add_alert' + elem + ' .title_disponibilite').hide();
            },
            fail: function () {
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}
/**
 *
 * @param orig = The original "add to cart" button from product page
 * @param clone = The cloned "add to cart" button
 * Override from master to fulfill client's willing (Cargoul Meunier, since 1978)
 */
function revealClone(orig, clone) {

    orig = document.getElementById(orig);
    clone = document.getElementById(clone);

    if (orig && clone) {

        output = 0;

        if (orig.getBoundingClientRect().top >= window.innerHeight - orig.offsetHeight) {

            output -= clone.offsetHeight;
        } else if (orig.getBoundingClientRect().top < 0) {

            output -= clone.offsetHeight;
        } else {

            output += clone.offsetHeight;
        }

        document.body.style.paddingBottom = (-output) + 'px';
        clone.style.marginTop = output + 'px';
    }
}
document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementById('add_basket_wrapper') && document.getElementById('floating_add_basket')) {
        revealClone('add_basket_wrapper', 'floating_add_basket');
    }
});

$(document).ready(function() {
    if ($("body.product_page").length && $(".swiper-container.asso_product_carousel").length) {
        var entretienSwiperMobile = new Swiper(".swiper-container.asso_product_carousel", {
            slidesPerView: 2.3561,
            spaceBetween: 10,
            allowTouchMove: true,
            freeMode: true,
            effect: "slide",
            observeParents: true,
            observer: true,
        });
    }
});

function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

function initDailymotionVideos() {
    $(".player-container-dailymotion").each(function () {
        try {
            dailymotion.createPlayer($(this).attr('id'), {
                video: $(this).attr('data-video-id'),
                params: {
                    loop: true,
                    mute: true
                }
            }).then(dailymotionPlayer => {
                if (dailymotionPlayer) {
                    $(".dailymotion-player-root").removeAttr("style").attr("style", "padding-bottom: 0 !important;position: static !important;margin-left: -250px !important;");
                    $(".dailymotion-player-wrapper").removeAttr("style");
                    const handleIntersections = (entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                dailymotionPlayer.play();
                            } else {
                                dailymotionPlayer.pause();
                            }
                        });
                    };
                    const observer = new IntersectionObserver(handleIntersections, {threshold: 0.5});
                    observer.observe(document.getElementById($(this).attr('id')));
                } else {
                    console.error("Aucun élément vidéo ou audio trouvé dans le conteneur du lecteur.");
                }
            }).catch((e) => console.error(e));
        } catch (error) {
            console.error("Une erreur sest produite lors de la création du lecteur :", error);
        }
    });
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });

    initDailymotionVideos();
});

function clickableBoxCookies() {
    const linkUrl = "https://business.safety.google/privacy/";

    // For #cookiePref02
    const $cookiePref02Label = $('#cookiePref02 label.w-input.w-checkbox-input');
    const $cookiePref02Block = $('<div></div>').css({
        position: 'absolute',
        width: '5%',
        height: '70%',
        top: '0.4rem',
        right: '43%',
        cursor: 'pointer',
        zIndex: 10,
        backgroundColor: 'transparent'
    }).click(function() {
        window.open(linkUrl, '_blank');
    });

    // Append the clickable block inside the label for #cookiePref02
    $cookiePref02Label.append($cookiePref02Block);

    // For #cookiePref03
    const $cookiePref03Label = $('#cookiePref03 label.w-input.w-checkbox-input');
    const $cookiePref03Block = $('<div></div>').css({
        position: 'absolute',
        width: '6%',
        height: '70%',
        top: '0.4rem',
        right: '47%',
        cursor: 'pointer',
        zIndex: 10,
        backgroundColor: 'transparent'
    }).click(function() {
        window.open(linkUrl, '_blank');
    });

    // Append the clickable block inside the label for #cookiePref03
    $cookiePref03Label.append($cookiePref03Block);
}

function btnLoaderTrigger(evt) {

    var element = $(this);
    var loader = $(this).next('.loader');
    let attrDisabled = $(this).attr('disabled');

    if (loader.length > 0 && (typeof attrDisabled === 'undefined' || !attrDisabled)) {
        evt.preventDefault();

        loader.removeAttr('style').addClass('loading');

        if ($(this).attr("href")) {

            window.location.assign($(this).attr("href"));
        }
        window.setTimeout($.fn.trigger.bind(element), 400, 'click');
    }
}
